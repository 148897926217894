import { useParams } from "react-router";
import { CollectDetailContainer } from "../../containers/CollectDetailContainer";
import { CollectListContainer } from "../../containers/CollectListContainer";

export const CollectDetailPage = () => {
  const { _id } = useParams();  

  return (
    <CollectDetailContainer _id={_id} />
  )
}
