import React from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { CustomerMenu } from "../../components/CustomerMenu";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SubMenu } from "./SubMenu";
import { FooterPanel } from "./FooterPanel";

export const FaqList = ({ category, faqs, pageInfo, onCategory, onPageSubmit, onSearchSubmit }) => {
  
  // 세부검색
  const searchOptions = [
    { value: 'title', label: '제목' },
    { value: 'body', label: '내용' },
  ];

  return (
    // FooterPanel를 맨 하위에 가져다 놓기 위해 wrap-container 엘러먼트 추가
    <div className="wrap-container">
    <div className="faq-list">
      <div className="div-3">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <CustomerMenu
          className="customer-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=10-114&mode=design"
          href1="https://store.gpakorea.com/cs/faq"
          href2="https://store.gpakorea.com/cs/voc"
          href3="https://store.gpakorea.com/cs/terms_of_use"
          href4="https://store.gpakorea.com/cs/privacy_policy"
          href5="https://store.gpakorea.com/cs/return_policy"
        />
        <div className="faq-body">
          <div className="FB">
            <div className="faq-body-title">
              <div className="span-bar" />
              <div className="heading-FAQ">FAQ</div>
            </div>

            {/* 서브메뉴 추가 */}
            <SubMenu category={category} onCategory={onCategory}/>

            <div className="faq-body-table">
              <div className="form-table">
                <div className="body-row-cell">
                  <div className="text-wrapper-25">번호</div>
                </div>
                <div className="body-row-cell-2">
                  <div className="text-wrapper-26">제목</div>
                </div>
                <div className="body-row-cell-3">
                  <div className="text-wrapper-27">카테고리</div>
                </div>
                <div className="body-row-cell-4">
                  <div className="text-wrapper-28">조회</div>
                </div>

                <ul>
                  {faqs.map((faq, index, total) => (
                    <li key={faq.id}>

                      <div className={`body-row-data-${1+4*index}`}>
                        <div className="text-wrapper-37">{faq.id}</div>
                      </div>
                      <Link to={`/faqdetail/${faq.id}`}>
                        <div className={`body-row-data-${2+4*index}`}>
                          <div className="text-wrapper-30">{faq.subject}</div>
                        </div>
                      </Link>
                      <div className={`body-row-data-${3+4*index}`}>
                        <div className="text-wrapper-34">{faq.category}</div>
                      </div>
                      <div className={`body-row-data-${4+4*index}`}>
                        <div className="text-wrapper-36">{faq.view.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                      </div>
                    </li>
                  ))}
                </ul>

              </div>
            </div>
            <BodyPage 
              className="body-page-instance" 
              pageInfo={ pageInfo } 
              onPageSubmit={onPageSubmit} 
            />
            <BodySearch className="body-search-instance" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit} />
          </div>
        </div>
        <KakaoService className="kakao-service-instance" />
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
        <div className="footer">
          <FooterPanel />
        </div>
      </div>
    </div>
    </div>
  );
};
