import * as determinedApi from "../api/determineds";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_DETERMINEDLIST = "GET_DETERMINEDLIST";
const GET_DETERMINEDLIST_SUCCESS = "GET_DETERMINEDLIST_SUCCESS";
const GET_DETERMINEDLIST_ERROR = "GET_DETERMINEDLIST_ERROR";

// 상품 항목 조회하기
const GET_DETERMINEDDETAIL = "GET_DETERMINEDDETAIL";
const GET_DETERMINEDDETAIL_SUCCESS = "GET_DETERMINEDDETAIL_SUCCESS";
const GET_DETERMINEDDETAIL_ERROR = "GET_DETERMINEDDETAIL_ERROR";

const CLEAR_DETERMINEDTLIST = "CLEAR_DETERMINEDTLIST";

export const getDeterminedList = (pageNumber, category, search, order) => ({
  type: GET_DETERMINEDLIST,
  payload: { page: pageNumber, category: category, search: search, order: order },
  meta: pageNumber,
});

export const getDeterminedDetail = (access, id) => ({
  type: GET_DETERMINEDDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearDeterminedList = () => ({ type: CLEAR_DETERMINEDLIST });

const getDeterminedListSaga = createPromiseSagaList(
  GET_DETERMINEDLIST,
  determinedApi.getDeterminedList
);

const getDeterminedDetailSaga = createPromiseSagaDetail(
  GET_DETERMINEDDETAIL,
  determinedApi.getDeterminedDetail
);

// 사가들을 합치기
export function* determinedSaga() {
  yield takeEvery(GET_DETERMINEDLIST, getDeterminedListSaga);
  yield takeEvery(GET_DETERMINEDDETAIL, getDeterminedDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  determinedlist: reducerUtils.initial(),
  determineddetail: reducerUtils.initial(),
};

export default function determinedReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DETERMINEDLIST:
    case GET_DETERMINEDLIST_SUCCESS:
    case GET_DETERMINEDLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_DETERMINEDLIST,
        "determinedlist",
        true
      );
      return reducerList(state, action);

    case GET_DETERMINEDDETAIL:
    case GET_DETERMINEDDETAIL_SUCCESS:
    case GET_DETERMINEDDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_DETERMINEDDETAIL,
        "determineddetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_DETERMINEDTLIST:
      return {
        ...state,
        determinedlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
