import * as subscribeapplyApi from "../api/subscribeapplys";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_SUBSCRIBEAPPLYLIST = "GET_SUBSCRIBEAPPLYLIST";
const GET_SUBSCRIBEAPPLYLIST_SUCCESS = "GET_SUBSCRIBEAPPLYLIST_SUCCESS";
const GET_SUBSCRIBEAPPLYLIST_ERROR = "GET_SUBSCRIBEAPPLYLIST_ERROR";

// 상품 항목 조회하기
const GET_SUBSCRIBEAPPLYDETAIL = "GET_SUBSCRIBEAPPLYDETAIL";
const GET_SUBSCRIBEAPPLYDETAIL_SUCCESS = "GET_SUBSCRIBEAPPLYDETAIL_SUCCESS";
const GET_SUBSCRIBEAPPLYDETAIL_ERROR = "GET_SUBSCRIBEAPPLYDETAIL_ERROR";

const CLEAR_SUBSCRIBEAPPLYTLIST = "CLEAR_SUBSCRIBEAPPLYTLIST";

export const getSubscribeApplyList = (mall, search, page) => ({
  type: GET_SUBSCRIBEAPPLYLIST,
  payload: { mall: mall, search: search, page: page, },
  meta: page,

});

export const getSubscribeApplyDetail = (data) => ({
  type: GET_SUBSCRIBEAPPLYDETAIL,
  payload: { data: data },
  meta: data.query.term._id.value,
});

const getSubscribeApplyListSaga = createPromiseSagaList(
  GET_SUBSCRIBEAPPLYLIST,
  subscribeapplyApi.getSubscribeApplyList
);

const getSubscribeApplyDetailSaga = createPromiseSagaDetail(
  GET_SUBSCRIBEAPPLYDETAIL,
  subscribeapplyApi.getSubscribeApplyDetail
);

// 사가들을 합치기
export function* subscribeapplySaga() {
  yield takeEvery(GET_SUBSCRIBEAPPLYLIST, getSubscribeApplyListSaga);
  yield takeEvery(GET_SUBSCRIBEAPPLYDETAIL, getSubscribeApplyDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  subscribeapplylist: reducerUtils.initial(),
  subscribeapplydetail: reducerUtils.initial(),
};

export default function subscribeapplyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIBEAPPLYLIST:
    case GET_SUBSCRIBEAPPLYLIST_SUCCESS:
    case GET_SUBSCRIBEAPPLYLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_SUBSCRIBEAPPLYLIST,
        "subscribeapplylist",
        true
      );
      return reducerList(state, action);

    case GET_SUBSCRIBEAPPLYDETAIL:
    case GET_SUBSCRIBEAPPLYDETAIL_SUCCESS:
    case GET_SUBSCRIBEAPPLYDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_SUBSCRIBEAPPLYDETAIL,
        "subscribeapplydetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_SUBSCRIBEAPPLYTLIST:
      return {
        ...state,
        subscribeapplylist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
