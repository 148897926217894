import * as subscribingApi from "../api/subscribings";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_SUBSCRIBINGLIST = "GET_SUBSCRIBINGLIST";
const GET_SUBSCRIBINGLIST_SUCCESS = "GET_SUBSCRIBINGLIST_SUCCESS";
const GET_SUBSCRIBINGLIST_ERROR = "GET_SUBSCRIBINGLIST_ERROR";

// 상품 항목 조회하기
const GET_SUBSCRIBINGDETAIL = "GET_SUBSCRIBINGDETAIL";
const GET_SUBSCRIBINGDETAIL_SUCCESS = "GET_SUBSCRIBINGDETAIL_SUCCESS";
const GET_SUBSCRIBINGDETAIL_ERROR = "GET_SUBSCRIBINGDETAIL_ERROR";

const CLEAR_SUBSCRIBINGTLIST = "CLEAR_SUBSCRIBINGTLIST";

export const getSubscribingList = (mall, search, page) => ({
  type: GET_SUBSCRIBINGLIST,
  payload: { mall: mall, search: search, page: page, },
  meta: page,

});

export const getSubscribingDetail = (data) => ({
  type: GET_SUBSCRIBINGDETAIL,
  payload: { data: data },
  meta: data.query.term._id.value,
});

const getSubscribingListSaga = createPromiseSagaList(
  GET_SUBSCRIBINGLIST,
  subscribingApi.getSubscribingList
);

const getSubscribingDetailSaga = createPromiseSagaDetail(
  GET_SUBSCRIBINGDETAIL,
  subscribingApi.getSubscribingDetail
);

// 사가들을 합치기
export function* subscribingSaga() {
  yield takeEvery(GET_SUBSCRIBINGLIST, getSubscribingListSaga);
  yield takeEvery(GET_SUBSCRIBINGDETAIL, getSubscribingDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  subscribinglist: reducerUtils.initial(),
  subscribingdetail: reducerUtils.initial(),
};

export default function subscribingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIBINGLIST:
    case GET_SUBSCRIBINGLIST_SUCCESS:
    case GET_SUBSCRIBINGLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_SUBSCRIBINGLIST,
        "subscribinglist",
        true
      );
      return reducerList(state, action);

    case GET_SUBSCRIBINGDETAIL:
    case GET_SUBSCRIBINGDETAIL_SUCCESS:
    case GET_SUBSCRIBINGDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_SUBSCRIBINGDETAIL,
        "subscribingdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_SUBSCRIBINGTLIST:
      return {
        ...state,
        subscribinglist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
