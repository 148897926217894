import axios from "./index";

class ContentApi {
  static getContentList = async (params) => {
    const response = await axios.get("/contents/", { params: params });
    return response.data.detail;
  };

  static getContentDetail = async (params) => {
    const response = await axios.get(`/contents/${params.id}/`);
    return response.data;
  };

  static postContentCreate = (formData) => {
    return axios.post(`contentcreate/`, formData);
  };

}

export default ContentApi;
