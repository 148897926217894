import React, { useState } from "react";
import queryString from "query-string";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";

export const BodyPage = ({ className, pageInfo, onPageSubmit }) => {
  const { totalCount, currPage, nextPage, prevPage } = pageInfo;             // 비구조화 할당
  const startPage = pageInfo.currPage > 2 ? pageInfo.currPage - 2 : 1;
  const totalPage = Math.ceil(totalCount / BODY_COUNT_PER_PAGE);
  
  const [disabled, setDisabled ] = useState(true);
  console.log(`totalPage=${totalPage}, startPage=${startPage}, currPage=${pageInfo.currPage}, totalCount=${pageInfo.totalCount}`)
  
  const onPrev = () => {
    const clickPage = (currPage - 1) < 1 ? 1 : currPage - 1;
    if (clickPage != currPage) {
      console.log(`onPrev: ${clickPage}`)
      onPageSubmit(clickPage);
    }
  }
  const onPage = (number, e) => {
    e.preventDefault();
    if (number != currPage && number <= totalPage ) {
      console.log(`onPage: ${number}`)
      onPageSubmit(number);
    }
  }
  const onNext = () => {
    const clickPage = (currPage + 1) > totalPage ? totalPage : currPage + 1;
    if (clickPage != currPage) {
      console.log(`onNext: ${clickPage}`)
      onPageSubmit(clickPage);
    }
  }

  function enablePage(thisPage) {
    return thisPage <= totalPage && thisPage != currPage ? true : false;
  }
  function setBorderColor(thisPage) {
    return thisPage <= totalPage ? '#222222' : '#cccccc';
  }
  function setTextColor(thisPage) {
    return (thisPage <= totalPage) ? ((thisPage != currPage) ? '#222222' : 'tomato') : '#cccccc';
  }
  return (
    <div className={`body-page ${className}`}>
      <div className="nav-link" style={{cursor: currPage <= 1 ? null : 'pointer', borderColor: currPage <= 1 ? '#cccccc' : '#222222'}} disabled={currPage <= 1 ? true : false} onClick={onPrev}>
        <div className="page-prev-gif" />
      </div>

      <div className="div-wrapper" style={{cursor: enablePage(startPage) ? 'pointer' : null, borderColor: setBorderColor(startPage)}} disabled={enablePage(startPage) ? false : true} onClick={(e)=>{onPage(startPage, e)}}>
        <div className="text-wrapper-3" style={{color: setTextColor(startPage)}}>{startPage}</div>
      </div>

      <div className="nav-link-2"style={{cursor: enablePage(startPage+1) ? 'pointer' : null, borderColor: setBorderColor(startPage+1)}} disabled={enablePage(startPage+1) ? false : true} onClick={(e)=>{onPage(startPage+1, e)}}>
        <div className="text-wrapper-3" style={{color: setTextColor(startPage+1)}}>{startPage+1}</div>
      </div>

      <div className="nav-link-3" style={{cursor: enablePage(startPage+2) ? 'pointer' : null, borderColor: setBorderColor(startPage+2)}} disabled={enablePage(startPage+2) ? false : true} onClick={(e)=>{onPage(startPage+2, e)}}>
        <div className="text-wrapper-3"style={{color: setTextColor(startPage+2)}}>{startPage+2}</div>
      </div>

      <div className="nav-link-4" style={{cursor: enablePage(startPage+3) ? 'pointer' : null, borderColor: setBorderColor(startPage+3)}} disabled={enablePage(startPage+3) ? false : true} onClick={(e)=>{onPage(startPage+3, e)}}>
        <div className="text-wrapper-3" style={{color: setTextColor(startPage+3)}}>{startPage+3}</div>
      </div>

      <div className="nav-link-5" style={{cursor: enablePage(startPage+4) ? 'pointer' : null, borderColor: setBorderColor(startPage+4)}} disabled={enablePage(startPage+4) ? false : true} onClick={(e)=>{onPage(startPage+4, e)}}>
        <div className="text-wrapper-3" style={{color: setTextColor(startPage+4)}}>{startPage+4}</div>
      </div>

      <div className="page-next-gif-wrapper" style={{cursor: nextPage && nextPage.length > 0 ? 'pointer' : null, borderColor: nextPage && nextPage.length > 0 ? '#222222' : '#cccccc' }} disabled={nextPage && nextPage.length > 0 ? false : true} onClick={onNext}>
        <div className="page-next-gif" />
      </div>
    </div>
  );
};
