import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductList } from "../components/ProductList";
import { getProductList, clearProductList } from "../modules/products";
import { useAuth } from "../contexts/auth/auth.context";

export const ProductListContainer = ({categoryParam = '', searchTypeParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ category, setCategory ] = useState(categoryParam);
  const [ searchType, setSearchType ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.productReducer.productlist);
  const dispatch = useDispatch();

  const onCategorySubmit = (categoryParm) => {
    setPage(1);
    setCategory(categoryParm);
  }

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParm, searchParm) => {
    setPage(1);
    setSearchType(searchTypeParm);
    setSearch(searchParm);
  }

  // 컴포넌트 마운트 후 목록 요청
  useEffect(() => {
    dispatch(getProductList(category, searchType, search, page));
  }, [dispatch, category, searchType, search, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <ProductList 
    dataParam={data}
    currPage = {page}
    onCategorySubmit={onCategorySubmit} 
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

