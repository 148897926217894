import React from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

export const LawsuitDetail = ({law}) => {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }

  return (
    <div className="ipr-detail">
      <div className="div-3">
        <FooterPanel className="footer-panel-instance" />
        <JimmedProducts className="design-component-instance-node-4" />
        <ViewedProducts className="design-component-instance-node-4" />
        <KakaoService className="design-component-instance-node-5" />
        <LinkTree className="design-component-instance-node-6" />
        <NavMenu className="design-component-instance-node-5" />
        <MainLogo className="main-logo-2" />
        <SearchArea className="design-component-instance-node-6" />
        <RightMenu className="right-menu-2" />
        <div className="body-frame-2">
          <div className="body-head-frame-2">
            <div className="text-wrapper-22">무료소송</div>
          </div>

          <div className="body-title-frame-2">
            <div className="text-wrapper-23"></div>
          </div>

          <div className="form-wrapper">
            <div className="form-2">
              <div className="fieldset-2">
                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">플랫폼:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.platform}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">진행상황:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.progress}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">판매상명:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.seller_name}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">제품:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.product}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">공증번호:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.notarization_no}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">피고:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.defendant}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">1심법원:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.first_trial_court}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">1심사건번호:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.first_trial_case_no}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">제품판매여부:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.sold_type}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">종결여부:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.finished}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-3">
                  <div className="container-5">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">비고:</div>
                    </div>

                    <div className="data-frame-7">
                      <div className="text-wrapper-26" dangerouslySetInnerHTML={{__html: law.comment}}></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">제출일자:</div>
                    </div>
                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.submit_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">입안일자:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.entried_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border-2">
                  <div className="container-4">
                    <div className="label-frame-2">
                      <div className="text-wrapper-24">공증일자:</div>
                    </div>

                    <div className="data-frame-5">
                      <div className="text-wrapper-25">{law.notarized_at.substring(0,10)}</div>
                    </div>

                    <div className="data-frame-6">
                      <div className="text-wrapper-25"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field-summary-2">
                <div className="dummy-frame-2" />

                <div className="list-frame-2" style={{cursor:'pointer'}} onClick={() => onBackward()}>
                  <div className="text-wrapper-27">목록</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
