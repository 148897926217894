import React, { useState, useRef, useContext, useCallback } from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MessagePanel } from "../../components/MessagePanel";
import { NavMenu } from "../../components/NavMenu";
import { PdBodyDesc } from "../../components/PdBodyDesc";
import { PdBodyPriceSide } from "../../components/PdBodyPriceSide";
import { PdBodyPolicy } from "../../components/PdBodyPolicy";
import { PdBodyPrice } from "../../components/PdBodyPrice";
import { PdBodyReview } from "../../components/PdBodyReview";
import { PdBodySimilar } from "../../components/PdBodySimilar";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaRegHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";

function ContentDetail({prd}) {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ jjimed, setJjimed ] = useState(0);
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  const refTab = useRef();
  const refDesc = useRef();
  const refPrice = useRef();
  const refPolicy = useRef();
  const refReview = useRef();
  const navigate = useNavigate();

  // 컨텐츠타입
  const typeOptions = { movie : "영상", music : "음악", webtoon : "웹툰", brand : "상표권" }

  // 침해 유형  
  const infringeOptions = { unallowed : "무단사용", illegalsite : "불법사이트" }

  // 신고 결과  
  const statusOptions = { ready : "대기",	apply : "신청",	confirm : "확정" }

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState(`${BACKEND_SERVER_BASEURL}${prd.thumb}`);
  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    const imgurl = `${BACKEND_SERVER_BASEURL}${thumburl}`
    setThumbClickedUrl(imgurl)
  }

  // 상품 장바구니 넣기
  function onBasket(product_id, price_id) {
    const formData = {
      product_id: product_id,
      price_id: price_id
    }
    productApi.postProductBasket(formData, {access: user.access}).then(async response => {
      if(response.status == 200 && response.data.code === "success") {
        const result = await confirmComp('장바구니에 넣었습니다. 장바구니로 이동하시겠습니까?');
        if (result === true) {
          navigate("/productbaskets");
        }
      } else {
        alert(response.data.detail)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  // 상품 구매하기
  function onPurchase(product_id, price_id) {
    const formData = {
      product_id: product_id,
      price_id: price_id
    }
    productApi.postProductSubscribes(data=formData, header={ access: user.access }).then(response => {
      if(response.status == 200) {
        alert(response.data.msg)
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
    
  }

  const handleScroll = useCallback(() => {
    if (!refTab.current || !refDesc.current || !refPrice.current || !refPolicy.current || !refReview.current || !document.getElementById('app'))
      return;

    // 스크롤의 실시간 위치
    const scrollTop = document.getElementById('app')?.scrollTop;
    const offset = window.pageYOffset;

    // 스크롤 위치가 refTab(하위메뉴 탭)의 위치보다 아래이면
    if (scrollTop >= refTab.current.offsetTop - offset) {
      setFixedTab(true);
    } else {
      setFixedTab(false);
    }
    console.log(`scrollTop=${scrollTop}, offset=${offset}, fixedTab=${fixedTab}`)

    // 스크롤 위치가 detailRef(하위메뉴 2번)의 위치보다 위이면
    if (scrollTop < refPrice.current.offsetTop - offset) {
      setJump('Desc');
    } 
    // 스크롤 위치가 detailRef(하위메뉴 2번)의 위치이거나 아래이면
    else if (scrollTop < refPolicy.current.offsetTop - offset) {
      setJump('Price');
    } 
    else if (scrollTop < refReview.current.offsetTop - offset) {
      setJump('Policy');
    } 
    else if (scrollTop >= refReview.current.offsetTop - offset) {
      setJump('Review');
    } 
  }, [refDesc.current, refPrice.current, refPolicy.current, refReview.current]);

  return (
    <div className="content-detail">
      <div className="div-2">
        <FooterPanel className="footer-panel-instance" />
        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <KakaoService className="design-component-instance-node-2" />
        <LinkTree className="design-component-instance-node-3" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <SearchArea className="design-component-instance-node-3" />
        <RightMenu className="right-menu-instance" />
        <div className="main-frame">
          <div className="section-frame">

            {/* // 내역 */}
            <div className="frame">
              <div className="frame-2">
                <div className="text-wrapper-15">컨텐츠 타입 : {typeOptions[prd.type]}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">카테고리 :</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-17">제목 : {prd.title}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">출처 : {prd.source}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">도메인 : {prd.domain}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">URL : {prd.url}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">침해유형 : {infringeOptions[prd.infringe]}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">상태 : {statusOptions[prd.statis]}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16"></div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16"></div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16"></div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16"></div>
              </div>
            </div>

            {/* // 썸네일 */}
            <div className="frame-3">
              <div className="img-wrapper">
                <img className="div-image-2" alt="Div image" src={thumbClickedUrl} />
              </div>

              <div className="frame-4">
                { 
                  [prd.thumb].map((thumburl, index, total) => (
                    thumburl && thumburl.length > 0 ?
                    <div className={`frame-${index+5}`} style={{cursor:'pointer', backgroundImage: `url(${BACKEND_SERVER_BASEURL}${prd.thumb})`, backgroundSize:'contain'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                    : null
                  ))
                }
              </div>

            </div>
          </div>

          <div className="rec-item-frame-wrapper">
            <div className="rec-item-frame">
              <div className="rec-item-left">
                <div className="thumbnail">
                  <div className="back-image-frame">
                    <img className="back-image" alt="Back image" src={`${BACKEND_SERVER_BASEURL}${prd.thumb}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-18">침해 내용</div>
            </div>
          </div>

          <div className="rec-item-frame-wrapper">
            <div className="rec-item-left-wrapper">
              <div className="rec-item-left-2" dangerouslySetInnerHTML={{__html: prd.content}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentDetail;