import React, {useState, useEffect } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LeftMenu } from "../../components/LeftMenu";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";

export const ReportList = ({ dataParam, currPage, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  // 몰
  const mallOptions = {ali: '알리',temu: '태무',1688: '일육팔팔',taobao: '타오바오',lazada: '라자다',amazon: '아마존',shopee: '쇼피',naver: '네이버쇼핑',coupang: '쿠팡'};

  // 컨텐츠타입
  const typeOptions = { movie : "영상", music : "음악", webtoon : "웹툰", brand : "상표권" }
  
  // 침해 유형  
  const infringeOptions = { unallowed : "무단사용", illegalsite : "불법사이트" }

  // 신고 결과  
  const statusOptions = { forger : "위조판매자",	seller : "정상판매자"}

  // 세부검색
  const searchOptions = [
    { value: 'shop', label: '쇼핑몰' },
    { value: 'seller_id', label: '아이디' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }
  return (
    <div className="report-list">
      <div className="div-2">
        <div className="bulletin-body" id="mainbody">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">위조품신고목록</div>
            </div>

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-21">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">쇼핑몰</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-23">제목</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-21">판매자</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-21">침해유형</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-21">신고일</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-21">확정일</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-21">상태</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (

                <Link className="record-frame" to={`/reports/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>
                  <div className="body-row-data">
                    <div className="text-wrapper-24">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-25">{mallOptions[prd.mall]}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-25">{prd.title}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-26">{prd.seller}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{infringeOptions[prd.infringe]}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-24">{prd.reported_at ? prd.reported_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-24">{prd.confirmed_at ? prd.confirmed_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-24">{statusOptions[prd.status]}</div>
                  </div>
                </Link>

                )) : <></>}

              </div>
            </div>

            {/* <BodyPage className="body-page-instance" />
            <BodySearch className="body-search-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}
            {pageInfo ?
              <BodySearch className="body-search-instance" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
            :<></>}            

          </div>
        </div>

        {/* // 1.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기, position/bodyBottom useState 추가,  */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/>
        
        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <LeftMenu className="left-menu-instance" />
        <KakaoService className="design-component-instance-node-2" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <div className="search-area-2">
          <div className="input-box-wrapper">
            <div className="input-box-2">검색어를 입력해주세요</div>
          </div>

          <div className="search-2">
            <div className="text-wrapper-27"></div>
          </div>
        </div>

        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
