import axios from "./index";

export const getSubscribingList = async (params) => {
  const response = await axios.get("/subscribings/", { params: params });
  return response.data;
};
export const getSubscribingDetail = async (id) => {
  const response = await axios.get(`/subscribings/${id}/`);
  return response.data;
};

class SubscribingApi {
  // 판별품 데이터 생성
  static InsertSubscribing = (formData) => {
    return axios.post(`/productsubscribings/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    // return axios.post(`/productsubscribings/`, formData, {headers:{"Content-Type": "application/json; charset=utf-8"}});

  };
}
export default SubscribingApi;