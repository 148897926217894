import SellerApi from "../api/sellers";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_SELLERLIST = "GET_SELLERLIST";
const GET_SELLERLIST_SUCCESS = "GET_SELLERLIST_SUCCESS";
const GET_SELLERLIST_ERROR = "GET_SELLERLIST_ERROR";

// 상품 항목 조회하기
const GET_SELLERDETAIL = "GET_SELLERDETAIL";
const GET_SELLERDETAIL_SUCCESS = "GET_SELLERDETAIL_SUCCESS";
const GET_SELLERDETAIL_ERROR = "GET_SELLERDETAIL_ERROR";

// 액션 생성 함수
export const getSellerList = (shop, searchType, search, page) => ({
  type: GET_SELLERLIST,
  payload: { shop: shop, searchType: searchType, search: search, page: page },
});
export const getSellerDetail = (access, id) => ({
  type: GET_SELLERDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

// GET_SELLERLIST라는 액션이 발생되면 SellerApi.getSellerList라는 특정 작업을 수행
const getSellerListSaga = createPromiseSagaList(
  GET_SELLERLIST,
  SellerApi.getSellerList
);
const getSellerDetailSaga = createPromiseSagaDetail(
  GET_SELLERDETAIL,
  SellerApi.getSellerDetail
);

// 사가들을 합치기(특정액션(GET_SELLERLIST)을 모니터링하고 있다가 액션이 발생되면 getSellerListSaga 인스턴스 실행)
export function* sellerSaga() {
  yield takeEvery(GET_SELLERLIST, getSellerListSaga);
  yield takeEvery(GET_SELLERDETAIL, getSellerDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  sellerlist: reducerUtils.initial(),
  sellerdetail: reducerUtils.initial(),
};

export default function sellerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SELLERLIST:
    case GET_SELLERLIST_SUCCESS:
    case GET_SELLERLIST_ERROR:
      const reducer = handleAsyncActionsList(
        GET_SELLERLIST,
        "sellerlist",
        true
      );
      return reducer(state, action);

    case GET_SELLERDETAIL:
    case GET_SELLERDETAIL_SUCCESS:
    case GET_SELLERDETAIL_ERROR:
      const reducer2 = handleAsyncActionsDetail(
        GET_SELLERDETAIL,
        "sellerdetail",
        true
      );
      return reducer2(state, action);
    default:
      return state;
  }
}
