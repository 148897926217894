import * as collectApi from "../api_elastic/collects";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_COLLECTLIST = "GET_COLLECTLIST";
const GET_COLLECTLIST_SUCCESS = "GET_COLLECTLIST_SUCCESS";
const GET_COLLECTLIST_ERROR = "GET_COLLECTLIST_ERROR";

// 상품 항목 조회하기
const GET_COLLECTDETAIL = "GET_COLLECTDETAIL";
const GET_COLLECTDETAIL_SUCCESS = "GET_COLLECTDETAIL_SUCCESS";
const GET_COLLECTDETAIL_ERROR = "GET_COLLECTDETAIL_ERROR";

const CLEAR_COLLECTTLIST = "CLEAR_COLLECTTLIST";

export const getCollectList = (data) => ({
  type: GET_COLLECTLIST,
  payload: { data: data },
  meta: data.size,
});

export const getCollectDetail = (data) => ({
  type: GET_COLLECTDETAIL,
  payload: { data: data },
  meta: data.query.term._id.value,
});

const getCollectListSaga = createPromiseSagaList(
  GET_COLLECTLIST,
  collectApi.getCollectList
);

const getCollectDetailSaga = createPromiseSagaDetail(
  GET_COLLECTDETAIL,
  collectApi.getCollectDetail
);

// 사가들을 합치기
export function* collectSaga() {
  yield takeEvery(GET_COLLECTLIST, getCollectListSaga);
  yield takeEvery(GET_COLLECTDETAIL, getCollectDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  collectlist: reducerUtils.initial(),
  collectdetail: reducerUtils.initial(),
};

export default function collectReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTLIST:
    case GET_COLLECTLIST_SUCCESS:
    case GET_COLLECTLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_COLLECTLIST,
        "collectlist",
        true
      );
      return reducerList(state, action);

    case GET_COLLECTDETAIL:
    case GET_COLLECTDETAIL_SUCCESS:
    case GET_COLLECTDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_COLLECTDETAIL,
        "collectdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_COLLECTTLIST:
      return {
        ...state,
        collectlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
