import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getIprDetail } from "../modules/iprs.js";
import {IprDetail} from "../components/IprDetail/IprDetail.jsx";

import { useAuth } from "../contexts/auth/auth.context.js";

export const IprDetailContainer = ( { id }) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const { data, loading, error } = useSelector(
    (state) => state.iprReducer.iprdetail[id]
    ) || {
      loading: false,
      data: null,
      error: null,
    };
  const dispatch = useDispatch();

  useEffect(() => {
    const access = (user ? user.access : '')
    dispatch(getIprDetail(access, id));
  }, [dispatch, id]);

  if (loading) return <div>로딩중...</div>;
  if (error) {
    return (
      <IprDetail ipr={error.data}/>
    );
  }
  else if (data) {
    return (
      <IprDetail ipr={data} />
    );
  }
}

