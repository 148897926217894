import React, {useState, useEffect } from "react";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LeftMenu } from "../LeftMenu";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { NavMenu } from "../NavMenu";
import { RightMenu } from "../RightMenu";
import { SearchArea } from "../SearchArea";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

export const CollectList = ({ categoryParam, searchWordParam, displayOrderParam, productsParam, onDisplayOrder, onSearchSubmit }) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  const navigate = useNavigate();

  const mallOptions = [
    { value: 'all', label: '모든쇼핑몰' },
    { value: 'alibaba', label: '알리바바' },
    { value: 'temu', label: '태무' },
    { value: '1688', label: '일육팔팔' },
    { value: 'taobao', label: '타오바오' },
    { value: 'lazada', label: '라자다' },
    { value: 'amazon', label: '아마존' },
    { value: 'shopee', label: '쇼피' },
    { value: 'naver', label: '네이버쇼핑' },
    { value: 'coupang', label: '쿠팡' },
  ];
  const [selectedMallOption, setSelectedMallOption] = useState('all');
  const handleSelectMallChange = (e) => {
    let value = e.target.value;
    setSelectedMallOption(value);
    navigate(`/collectlist?mall=${value}&searchType=${searchCondition}`);
  };

  const searchConditions = {
    'all': '전체 상품',
    'image': '이미지 상품',
    'keyword': '키워드 상품'
  }
  const [searchCondition, setSearchCondition] = useState('all');
  const handleSearchChange = (search, e) => {
    e.preventDefault();
    setSearchCondition(search);
    navigate(`/collectlist?mall=${selectedMallOption}&searchType=${search}`);
  };
  
  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="collect-list">
      <div className="div-2">
        {/* // 1.FooterPanel의 className을 "footer-panel-instance"로, 2.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/> 

        { productsParam ? (

        <div className="product-list-body" id="mainbody">
          <div className="best-tag">
            <div className="caption-frame">
              <div className="arrange-title">{searchConditions[searchCondition]}</div>
            </div>
            <select className="mall-frame" style={{height:"24px"}} id="infringe" name="infringe" value={selectedMallOption} onChange={handleSelectMallChange}>
              { mallOptions.map((option, index, total) => ( 
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
            <div className="best-box" onClick={(e) => {handleSearchChange('all', e)}} style={{cursor:'pointer', backgroundColor: (searchCondition === 'all') ? 'tomato' : '#ffffff'}}>
              <div className="text-wrapper-19" style={{color: (searchCondition === 'all') ? '#ffffff' : '#000000' }}>A</div>
            </div>
            <div className="div-wrapper-2" onClick={(e) => {handleSearchChange('image', e)}} style={{cursor:'pointer', backgroundColor: (searchCondition === 'image') ? 'tomato' : '#ffffff'}}>
              <div className="text-wrapper-19" style={{color: (searchCondition === 'image') ? '#ffffff' : '#000000' }}>I</div>
            </div>
            <div className="div-wrapper-2" onClick={(e) => {handleSearchChange('keyword', e)}} style={{cursor:'pointer', backgroundColor: (searchCondition === 'keyword') ? 'tomato' : '#ffffff'}}>
              <div className="text-wrapper-19" style={{color: (searchCondition === 'keyword') ? '#ffffff' : '#000000' }}>K</div>
            </div>
          </div>

          <div className="best-body">
            
          {productsParam.map((dtm, index, total) => (
          <Link to={`/collectdetail/${dtm._id}`} key={dtm._id}>            

            <div className="best-item">
              <div className="thumbnail" >
                <div className="overlap-group" style={{cursor:'pointer', backgroundImage: `url(${dtm._source.product_similar.length > 0 ? dtm._source.product_similar[0].determin : null })`, backgroundSize:'corner'}}>
                  <button className="button-box-wrapper" >
                    <div className="button-box"></div>
                  </button>
                </div>
              </div>
              <div className="item-info">
                <div className="item-title">
                  <p className="item-title-text">{dtm._source.determin_name.substr(0,40)}</p>
                </div>
                <div className="item-price">
                  <div className="item-price-text">{dtm._source.product_price} / {dtm._source.price_floor} / {dtm._source.determin_price}</div>
                </div>
                <div className="item-review">
                  <div className="item-review-symbol">{dtm._source.mall}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <div className="text-wrapper-20">{dtm._source.searchType}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <div className="item-review-tag">|&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <div className="text-wrapper-20">{dtm._source.seller}</div>
                </div>
              </div>
            </div>
            
          </Link>
          ))}

          </div>
        </div>

        ) : (
          <></>
        )}        

        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <LeftMenu className="left-menu-instance" category={(categoryParam && categoryParam.length >= 4) ? categoryParam.substring(0,2) : categoryParam}/>
        <KakaoService className="design-component-instance-node-2" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <div className="search-area-2" onSearchSubmit={onSearchSubmit}>
          <div className="input-box-wrapper">
            <div className="input-box">검색어를 입력해주세요</div>
          </div>
          <div className="search-2">
            <div className="text-wrapper-21"></div>
          </div>
        </div>
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
