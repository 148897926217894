import ReportApi from "../api/reports";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_REPORTLIST = "GET_REPORTLIST";
const GET_REPORTLIST_SUCCESS = "GET_REPORTLIST_SUCCESS";
const GET_REPORTLIST_ERROR = "GET_REPORTLIST_ERROR";

// 상품 항목 조회하기
const GET_REPORTDETAIL = "GET_REPORTDETAIL";
const GET_REPORTDETAIL_SUCCESS = "GET_REPORTDETAIL_SUCCESS";
const GET_REPORTDETAIL_ERROR = "GET_REPORTDETAIL_ERROR";

// 액션 생성 함수
export const getReportList = (mall, searchType, search, page) => ({
  type: GET_REPORTLIST,
  payload: { mall: mall, searchType: searchType, search: search, page: page },
});
export const getReportDetail = (access, id) => ({
  type: GET_REPORTDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

// GET_REPORTLIST라는 액션이 발생되면 ReportApi.getReportList라는 특정 작업을 수행
const getReportListSaga = createPromiseSagaList(
  GET_REPORTLIST,
  ReportApi.getReportList
);
const getReportDetailSaga = createPromiseSagaDetail(
  GET_REPORTDETAIL,
  ReportApi.getReportDetail
);

// 사가들을 합치기(특정액션(GET_REPORTLIST)을 모니터링하고 있다가 액션이 발생되면 getReportListSaga 인스턴스 실행)
export function* reportSaga() {
  yield takeEvery(GET_REPORTLIST, getReportListSaga);
  yield takeEvery(GET_REPORTDETAIL, getReportDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  reportlist: reducerUtils.initial(),
  reportdetail: reducerUtils.initial(),
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTLIST:
    case GET_REPORTLIST_SUCCESS:
    case GET_REPORTLIST_ERROR:
      const reducer = handleAsyncActionsList(
        GET_REPORTLIST,
        "reportlist",
        true
      );
      return reducer(state, action);

    case GET_REPORTDETAIL:
    case GET_REPORTDETAIL_SUCCESS:
    case GET_REPORTDETAIL_ERROR:
      const reducer2 = handleAsyncActionsDetail(
        GET_REPORTDETAIL,
        "reportdetail",
        true
      );
      return reducer2(state, action);
    default:
      return state;
  }
}
