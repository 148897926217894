import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeterminList } from "../components/DeterminList";
import { getDeterminList } from "../modules/determins";
import { useAuth } from "../contexts/auth/auth.context";

export const DeterminListContainer = ({mallParam = 'all', searchTypeParam = 'all'}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const [ currPage, setCurrPage ] = useState(1);
  const [ category, setCategory ] = useState('');
  const [ displayOrder, setDisplayOrder ] = useState('best_desc');
  const [ searchWord, setSearchWord ] = useState('');

  const { data, loading, error} = useSelector((state) => state.determinReducer.determinlist);
  const dispatch = useDispatch();

  const onDisplayOrder = (orderParm) => {
    setDisplayOrder(orderParm);
  }

  const onSearchSubmit = (searchParm) => {
    setSearchWord(searchParm);
  }

  // 컴포넌트 마운트 후 목록 요청
  //       { script: { script: doc['determin_price'].value < doc['price_floor'].value}}      
  useEffect(() => {
    let params = { 
      size: 100, 
      sort: [ 
        { created_at: { order: "desc", format: "strict_date_optional_time", unmapped_type: "boolean" } }, 
        { "_doc": { "order": "desc", "unmapped_type": "boolean" } } 
      ],      
      query : { bool: { filter: [ 
      { script: { script: "doc['determin_price'].value < doc['price_floor'].value"}}
    ]}}}
    if (mallParam != 'all' && searchTypeParam != 'all') {
      params = { 
        size: 100, 
        sort: [ 
          { created_at: { order: "desc", format: "strict_date_optional_time", unmapped_type: "boolean" } }, 
          { "_doc": { "order": "desc", "unmapped_type": "boolean" } } 
        ],      
        query : { bool: { filter: [
        { match: { mall: `${mallParam}` }}, { match: { search_type: `${searchTypeParam}` }}, { script: { script: "doc['determin_price'].value < doc['price_floor'].value"}}
      ]}}}
    }
    else if (mallParam != 'all') {
      params = { 
        size: 100, 
        sort: [ 
          { created_at: { order: "desc", format: "strict_date_optional_time", unmapped_type: "boolean" } }, 
          { "_doc": { "order": "desc", "unmapped_type": "boolean" } } 
        ],      
        query : { bool: { filter: [
        { match: { mall: `${mallParam}` }}, { script: { script: "doc['determin_price'].value < doc['price_floor'].value"}}
      ]}}}
    }
    else if (searchTypeParam != 'all') {
      params = { 
        size: 100, 
        sort: [ 
          { created_at: { order: "desc", format: "strict_date_optional_time", unmapped_type: "boolean" } }, 
          { "_doc": { "order": "desc", "unmapped_type": "boolean" } } 
        ],      
        query : { bool: { filter: [
        { match: { search_type: `${searchTypeParam}` }}, { script: { script: "doc['determin_price'].value < doc['price_floor'].value"}}
      ]}}}
    }
    dispatch(getDeterminList(params));
  }, [dispatch, mallParam, searchTypeParam, searchWord, displayOrder]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <DeterminList categoryParam={category} searchWordParam={searchWord} displayOrderParam={displayOrder} productsParam={data} onDisplayOrder={onDisplayOrder} onSearchSubmit={onSearchSubmit}/>
  );
}

