import * as productApi from "../api/products";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_PRODUCTLIST = "GET_PRODUCTLIST";
const GET_PRODUCTLIST_SUCCESS = "GET_PRODUCTLIST_SUCCESS";
const GET_PRODUCTLIST_ERROR = "GET_PRODUCTLIST_ERROR";

// 상품 항목 조회하기
const GET_PRODUCTDETAIL = "GET_PRODUCTDETAIL";
const GET_PRODUCTDETAIL_SUCCESS = "GET_PRODUCTDETAIL_SUCCESS";
const GET_PRODUCTDETAIL_ERROR = "GET_PRODUCTDETAIL_ERROR";

// 상품 정책 조회하기
const GET_PRODUCTPOLICYS = "GET_PRODUCTPOLICYS";
const GET_PRODUCTPOLICYS_SUCCESS = "GET_PRODUCTPOLICYS_SUCCESS";
const GET_PRODUCTPOLICYS_ERROR = "GET_PRODUCTPOLICYS_ERROR";

// 상품 리뷰 조회하기
const GET_PRODUCTREVIEWS = "GET_PRODUCTREVIEWS";
const GET_PRODUCTREVIEWS_SUCCESS = "GET_PRODUCTREVIEWS_SUCCESS";
const GET_PRODUCTREVIEWS_ERROR = "GET_PRODUCTREVIEWS_ERROR";

// 장바구니 목록 조회하기
const GET_PRODUCTBASKETS = "GET_PRODUCTBASKETS";
const GET_PRODUCTBASKETS_SUCCESS = "GET_PRODUCTBASKETS_SUCCESS";
const GET_PRODUCTBASKETS_ERROR = "GET_PRODUCTBASKETS_ERROR";

// 상품 가격 목록 조회하기
const GET_PRODUCTPRICES = "GET_PRODUCTPRICES";
const GET_PRODUCTPRICES_SUCCESS = "GET_PRODUCTPRICES_SUCCESS";
const GET_PRODUCTPRICES_ERROR = "GET_PRODUCTPRICES_ERROR";

// 유사 상품 목록 조회하기
const GET_PRODUCTSIMILARS = "GET_PRODUCTSIMILARS";
const GET_PRODUCTSIMILARS_SUCCESS = "GET_PRODUCTSIMILARS_SUCCESS";
const GET_PRODUCTSIMILARS_ERROR = "GET_PRODUCTSIMILARS_ERROR";

const CLEAR_PRODUCTTLIST = "CLEAR_PRODUCTTLIST";

export const getProductList = (category, searchType, search, pageNumber) => ({
  type: GET_PRODUCTLIST,
  payload: { category: category, searchType: searchType, search: search, page: pageNumber },
});

export const getProductDetail = (access, id) => ({
  type: GET_PRODUCTDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const getProductPolicys = (category) => ({
  type: GET_PRODUCTPOLICYS,
  payload: { category: category },
  meta: category,
});

export const getProductReviews = (id) => ({
  type: GET_PRODUCTREVIEWS,
  payload: { id: id },
  meta: id,
});

export const getProductBaskets = (id, access) => ({
  type: GET_PRODUCTBASKETS,
  payload: { id: id, access: access },
  meta: id,
});

export const getProductPrices = (id) => ({
  type: GET_PRODUCTPRICES,
  payload: { id: id },
  meta: id,
});

export const getProductSimilars = (category) => ({
  type: GET_PRODUCTSIMILARS,
  payload: { category: category },
  meta: category,
});

export const clearProductList = () => ({ type: CLEAR_PRODUCTTLIST });

const getProductListSaga = createPromiseSagaList(
  GET_PRODUCTLIST,
  productApi.getProductList
);

const getProductDetailSaga = createPromiseSagaDetail(
  GET_PRODUCTDETAIL,
  productApi.getProductDetail
);

const getProductPolicysSaga = createPromiseSagaList(
  GET_PRODUCTPOLICYS,
  productApi.getProductPolicys
);

const getProductReviewsSaga = createPromiseSagaList(
  GET_PRODUCTREVIEWS,
  productApi.getProductReviews
);

const getProductBasketsSaga = createPromiseSagaList(
  GET_PRODUCTBASKETS,
  productApi.getProductBaskets
);

const getProductPricesSaga = createPromiseSagaList(
  GET_PRODUCTPRICES,
  productApi.getProductPrices
);

const getProductSimilarsSaga = createPromiseSagaList(
  GET_PRODUCTSIMILARS,
  productApi.getProductSimilars
);

// 사가들을 합치기
export function* productSaga() {
  yield takeEvery(GET_PRODUCTLIST, getProductListSaga);
  yield takeEvery(GET_PRODUCTDETAIL, getProductDetailSaga);
  yield takeEvery(GET_PRODUCTPOLICYS, getProductPolicysSaga);
  yield takeEvery(GET_PRODUCTREVIEWS, getProductReviewsSaga);
  yield takeEvery(GET_PRODUCTBASKETS, getProductBasketsSaga);
  yield takeEvery(GET_PRODUCTPRICES, getProductPricesSaga);
  yield takeEvery(GET_PRODUCTSIMILARS, getProductSimilarsSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  productlist: reducerUtils.initial(),
  productdetail: reducerUtils.initial(),
  productsimilars: reducerUtils.initial(),
  productpolicys: reducerUtils.initial(),
  productreviews: reducerUtils.initial(),
  productbaskets: reducerUtils.initial(),
  productprices: reducerUtils.initial(),
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTLIST:
    case GET_PRODUCTLIST_SUCCESS:
    case GET_PRODUCTLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_PRODUCTLIST,
        "productlist",
        true
      );
      return reducerList(state, action);

    case GET_PRODUCTDETAIL:
    case GET_PRODUCTDETAIL_SUCCESS:
    case GET_PRODUCTDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_PRODUCTDETAIL,
        "productdetail",
        true
      );
      return reducerDetail(state, action);

    case GET_PRODUCTSIMILARS:
    case GET_PRODUCTSIMILARS_SUCCESS:
    case GET_PRODUCTSIMILARS_ERROR:
      const reducerSimilars = handleAsyncActionsList(
        GET_PRODUCTSIMILARS,
        "productsimilars",
        true
      );
      return reducerSimilars(state, action);

    case GET_PRODUCTPOLICYS:
    case GET_PRODUCTPOLICYS_SUCCESS:
    case GET_PRODUCTPOLICYS_ERROR:
      const reducerPolicys = handleAsyncActionsList(
        GET_PRODUCTPOLICYS,
        "productpolicys",
        true
      );
      return reducerPolicys(state, action);

    case GET_PRODUCTREVIEWS:
    case GET_PRODUCTREVIEWS_SUCCESS:
    case GET_PRODUCTREVIEWS_ERROR:
      const reducerReviews = handleAsyncActionsList(
        GET_PRODUCTREVIEWS,
        "productreviews",
        true
      );
      return reducerReviews(state, action);

    case GET_PRODUCTBASKETS:
    case GET_PRODUCTBASKETS_SUCCESS:
    case GET_PRODUCTBASKETS_ERROR:
      const reducerBaskets = handleAsyncActionsList(
        GET_PRODUCTBASKETS,
        "productbaskets",
        true
      );
      return reducerBaskets(state, action);

    case GET_PRODUCTPRICES:
    case GET_PRODUCTPRICES_SUCCESS:
    case GET_PRODUCTPRICES_ERROR:
      const reducerPrices = handleAsyncActionsList(
        GET_PRODUCTPRICES,
        "productprices",
        true
      );
      return reducerPrices(state, action);

    case CLEAR_PRODUCTTLIST:
      return {
        ...state,
        productlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
