import axios from "./index";

export const getDeterminedList = async (params) => {
  const response = await axios.get("/determineds/", { params: params });
  return response.data;
};
export const getDeterminedDetail = async (id) => {
  const response = await axios.get(`/determineds/${id}/`);
  return response.data;
};

class DeterminedApi {
  // 판별품 데이터 생성
  static InsertDetermined = (formData) => {
    return axios.post(`/productdetermineds/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };
  
  // 수집품 확인 데이터 생성
  static InsertConfirmed = (formData) => {
    return axios.post(`/confirmed/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };


  
}
export default DeterminedApi;