import { combineReducers } from "redux";
import counter, { counterSaga } from "./counter";
import posts, { postSaga } from "./posts";
import authReducer, { authSaga } from "./auth";
import bulletinReducer, { bulletinSaga } from "./bulletins";
import faqReducer, { faqSaga } from "./faqs";
import inquiryReducer, { inquirySaga } from "./inquirys";
import { all } from "redux-saga/effects";
import forgeryReducer, { forgerySaga } from "./forgerys";
import productReducer, { productSaga } from "./products";
import subscribeapplyReducer, { subscribeapplySaga } from "./subscribeapplys";
import subscribingReducer, { subscribingSaga } from "./subscribings";
import collectReducer, { collectSaga } from "./collects";
import contentReducer, { contentSaga } from "./contents";
import determinReducer, { determinSaga } from "./determins";
import determinedReducer, { determinedSaga } from "./determineds";
import categoryReducer, { categorySaga } from "./categorys";
import iprReducer, { iprSaga } from "./iprs";
import lawsuitReducer, { lawsuitSaga } from "./lawsuits";
import sellerReducer, { sellerSaga } from "./sellers";
import reportReducer, { reportSaga } from "./reports";

const rootReducer = combineReducers({
  counter,
  posts,
  authReducer,
  bulletinReducer,
  faqReducer,
  inquiryReducer,
  productReducer,
  subscribeapplyReducer,
  subscribingReducer,
  collectReducer,
  contentReducer,
  determinReducer,
  determinedReducer,
  forgeryReducer,
  iprReducer,
  lawsuitReducer,
  categoryReducer,
  sellerReducer,
  reportReducer,
});

export function* rootSaga() {
  yield all([
    counterSaga(),
    postSaga(),
    authSaga(),
    bulletinSaga(),
    faqSaga(),
    forgerySaga(),
    inquirySaga(),
    productSaga(),
    subscribeapplySaga(),
    subscribingSaga(),
    collectSaga(),
    contentSaga(),
    determinSaga(),
    determinedSaga(),
    iprSaga(),
    lawsuitSaga(),
    categorySaga(),
    sellerSaga(),
    reportSaga(),
  ]);
}

export default rootReducer;
