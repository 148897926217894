import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ForgeryListContainer } from "../../containers/ForgeryListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const ForgeryListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category")
  const searchTypeParam = searchParams.get("searchType")
  const searchParam = searchParams.get("search")
  const pageParam = searchParams.get("page")

  // 인수로 넘어온값이 없는 경우 Default 값 설정
  const category = (categoryParam && categoryParam.length > 0) ? categoryParam : '';
  const searchType = (searchTypeParam && searchTypeParam.length > 0) ? searchTypeParam : 'all';
  const search = (searchParam && searchParam.length > 0) ? searchParam : '';
  const page = (pageParam && pageParam.length > 0) ? pageParam : 1;

  // // 기존에 설정된 카테고리 클리어
  // useEffect(() => {
  //   if (category === 'all') {
  //     setGlobal((global) => ({
  //       ...global,
  //       menuCategoryLevel: 0,
  //       menuCategoryCode: 'all',
  //       menuCategoryName: "전체",
  //       submenuCategoryCode: '',
  //       submenuCategoryName: '',        
  //       order: 'best_desc',
  //     }));
  //   }
  // }, []);

  return (
    <ForgeryListContainer 
    categoryParam ={category} 
    searchTypeParam ={searchType} 
    searchParam ={search} 
    pageParam ={page} 
    />
  );
}

