import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";

import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";
import { getCategoryTree } from "../../modules/categorys";
// import menuData from './data.json'
import LeftMenuItem from './LeftMenuItem'

export const LeftMenu = ({ className, category = 'all' }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();
  const [ search, setSearch ] = useState("");
  const { data, loading, error } = useSelector(
    (state) => state.categoryReducer.categorytree
  );
  const dispatch = useDispatch();
 
  const nest = (menuData, code = "ROOT", parent_code = 'parent_code') =>
    menuData.filter(item => item[parent_code] === code)
      .map(item => ({ ...item, childrens: nest(menuData, item.code) }));

  useEffect(() => {
    // 메인 카테고리(ex:NS,IG등)의 경우에만 Request
    dispatch(getCategoryTree(category));
  }, [dispatch]); // , category
 
  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  // 상단 카테고리 메뉴를 클릭했을때 서버로부터 데이터를 다시 가져옴
  if (category && category.length > 0 && category != search) {
    setSearch(category);
  }
  // category가 '' 또는 'all'인 경우 'ROOT'를 그외엔 category 값으로 nest호출
  const tree = data ? nest(data, ((category && category !== 'all') ? category : 'ROOT')) : [];

  return (
    <div className={`left-menu ${className}`}>
      <div className="left-menu-head">
        <div className="text-wrapper-41" style={{color:"tomato"}}>{global['menuCategoryName']}</div> 
      </div>
      <div className="left-menu-list">
        {tree.map((item, index) =>
          <LeftMenuItem item={item} key={index} />
        )}
      </div>
    </div>
  );
};

LeftMenu.propTypes = {
  href: PropTypes.string,
  href1: PropTypes.string,
  href2: PropTypes.string,
  href3: PropTypes.string,
  href4: PropTypes.string,
  href5: PropTypes.string,
  href6: PropTypes.string,
  href7: PropTypes.string,
};
