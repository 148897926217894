import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LeftMenu } from "../../components/LeftMenu";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";

export const ForgeryList = ({ dataParam, currPage, onPlatformSubmit, onCategorySubmit, onInfringeSubmit, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const refToDate = useRef();
  // 지재권유형
  const iprTypes = {brand: '상표', copyright: '저작권', design: '디자인', patent: '특허'};

  // 카테고리  
  const platformOptions = [
    { value: 'all', label: '모두' },
    { value: 'alibaba', label: '알리바바' },
    { value: 'temu', label: '태무' },
    { value: '1688', label: '일육팔팔' },
    { value: 'taobao', label: '타오바오' },
    { value: 'lazada', label: '라자다' },
    { value: 'amazon', label: '아마존' },
    { value: 'shopee', label: '쇼피' },
    { value: 'naver', label: '네이버쇼핑' },
    { value: 'coupang', label: '쿠팡' },
  ];  
  const platforms = {
    'alibaba': '알리바바',
    'temu': '태무',
    '1688': '일육팔팔',
    'taobao': '타오바오',
    'lazada': '라자다',
    'amazon': '아마존',
    'shopee': '쇼피',
    'naver': '네이버쇼핑',
    'coupang': '쿠팡',
  }
  const [platform, setPlatform] = useState('');
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  // 카테고리  
  const categoryOptions = [
    { value: 'all', label: '모두' },
    { value: 'beauty', label: '화장품' },
    { value: 'clothes', label: '의류' },
    { value: 'bags', label: '가방' },
    { value: 'appliance', label: '가전' },
    { value: 'food', label: '음식료' },
    { value: 'furniture', label: '가구' },
    { value: 'infant', label: '아동/유아' },
    { value: 'contents', label: '컨텐츠' },
    { value: 'leasure', label: '레저' },
    { value: 'character', label: '캐릭터' },
    { value: 'medical', label: '의료기기' },
  ];  
  const categories = {
    'beauty': '화장품',
    'clothes': '의류',
    'bags': '가방',
    'appliance': '가전',
    'food': '음식료',
    'furniture': '가구',
    'infant': '아동/유아',
    'contents': '컨텐츠',
    'leasure': '레저',
    'character': '캐릭터',
    'medical': '의료기기',
  }
  const [category, setCategory] = useState('');
  const handleCategory = (e) => {
    let value = e.target.value;
    setCategory(value);
    onCategorySubmit(value);
  };

  // 침해유형  
  const infringeOptions = [
    { value: '', label: '모두' },
    { value: 'image_copy', label: '이미지도용(모방)' },
    { value: 'image_realbuy', label: '이미지도용(직구)' },
    { value: 'image_theft', label: '이미지도용(공홈)' },
    { value: 'brand_infrange', label: '상표훼손(모자이크)' },
    { value: 'keyword_theft', label: '키워드도용' },
    { value: 'copyright_infrange', label: '저작권침해(캐릭터)' },
    { value: 'genuine', label: '정품' },
  ];  
  const infringes = {
    'image_copy': '이미지도용(모방)',
    'image_realbuy': '이미지도용(직구)',
    'image_theft': '이미지도용(공홈)',
    'brand_infrange': '상표훼손(모자이크)',
    'keyword_theft': '키워드도용',
    'copyright_infrange': '저작권침해(캐릭터)',
    'genuine': '정품',
  }
  const [infringe, setInfringe] = useState('');
  const handleInfringe = (e) => {
    let value = e.target.value;
    setInfringe(value);
    onInfringeSubmit(value);
  };

  // 세부검색
  const searchOptions = [
    { value: '', label: '' },
    { value: 'determin_name', label: '수집품명' },
    { value: 'content', label: '내용' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="forgery-list">
      <div className="div-2">
        <div className="bulletin-body" id="mainbody">
          <div className="body">
            <div className="body-title">
              <div className="title">가품정보관리</div>
            </div>

            <div className="body-sub-title" />

            <div className="body-head">
              <div className="drop-downs">
                <div className="dropdown">
                  <div className="text-wrapper-20">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  <select className="text-wrapper-21" style={{width:"95px", height:"26px", border:"none"}} id="platform" name="platform" value={platform} onChange={handlePlatform}>
                    { platformOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-20">카테고리</div>
                </div>

                <div className="dropdown-4">
                  <select className="text-wrapper-21" style={{width:"95px", height:"26px", border:"none"}} id="category" name="category" value={category} onChange={handleCategory}>
                    { categoryOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>

                <div className="dropdown-3">
                  <div className="text-wrapper-20">침해유형</div>
                </div>

                <div className="dropdown-2">
                  <select className="text-wrapper-21" style={{width:"95px", height:"26px", border:"none"}} id="infringe" name="infringe" value={infringe} onChange={handleInfringe}>
                    { infringeOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>
              </div>

              {pageInfo ?
              <BodySearch className="design-component-instance-node" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>

            <div className="body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-22">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">플랫폼</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">브랜드</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">모델</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">카테고리</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-23">제품명</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-22">침해유형</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">최종가격</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">정품하한</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">판매자</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">생성일</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame" to={`/forgerydetail/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>
                  <div className="body-row-data">
                    <div className="text-wrapper-24">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-24">{platforms[prd.mall]}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-24">{prd.brand}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.model}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.category}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-24">{prd.determin_name.substring(0,8)}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-24">{infringes[prd.infringe].substring(0,5)}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.determin_price ? prd.determin_price.toLocaleString('ko-KR') : null}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.price_floor.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.seller}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.created_at ? prd.created_at.substr(2,8) : null}</div>
                  </div>
                </Link>
                )) : <></>}

                
              </div>
            </div>

            {/* <BodyPage className="design-component-instance-node" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>

        {/* // 1.FooterPanel의 className을 "footer-panel-instance"로, 2.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/> 

        <JimmedProducts className="design-component-instance-node-2" />
        <ViewedProducts className="design-component-instance-node-2" />
        <LeftMenu className="left-menu-instance" />
        <KakaoService className="design-component-instance-node-3" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-3" />
        <MainLogo className="main-logo-instance" />
        <div className="search-area-2">
          <div className="input-box-wrapper">
            <div className="input-box-2">검색어를 입력해주세요</div>
          </div>

          <div className="search-2">
            <div className="text-wrapper-25"></div>
          </div>
        </div>

        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
