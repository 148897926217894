import ContentApi from "../api/contents";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_CONTENTLIST = "GET_CONTENTLIST";
const GET_CONTENTLIST_SUCCESS = "GET_CONTENTLIST_SUCCESS";
const GET_CONTENTLIST_ERROR = "GET_CONTENTLIST_ERROR";

// 상품 항목 조회하기
const GET_CONTENTDETAIL = "GET_CONTENTDETAIL";
const GET_CONTENTDETAIL_SUCCESS = "GET_CONTENTDETAIL_SUCCESS";
const GET_CONTENTDETAIL_ERROR = "GET_CONTENTDETAIL_ERROR";

// 액션 생성 함수
export const getContentList = (type, searchType, search, page) => ({
  type: GET_CONTENTLIST,
  payload: { type: type, searchType: searchType, search: search, page: page },
});
export const getContentDetail = (access, id) => ({
  type: GET_CONTENTDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

// GET_CONTENTLIST라는 액션이 발생되면 ContentApi.getContentList라는 특정 작업을 수행
const getContentListSaga = createPromiseSagaList(
  GET_CONTENTLIST,
  ContentApi.getContentList
);
const getContentDetailSaga = createPromiseSagaDetail(
  GET_CONTENTDETAIL,
  ContentApi.getContentDetail
);

// 사가들을 합치기(특정액션(GET_CONTENTLIST)을 모니터링하고 있다가 액션이 발생되면 getContentListSaga 인스턴스 실행)
export function* contentSaga() {
  yield takeEvery(GET_CONTENTLIST, getContentListSaga);
  yield takeEvery(GET_CONTENTDETAIL, getContentDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  contentlist: reducerUtils.initial(),
  contentdetail: reducerUtils.initial(),
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTENTLIST:
    case GET_CONTENTLIST_SUCCESS:
    case GET_CONTENTLIST_ERROR:
      const reducer = handleAsyncActionsList(
        GET_CONTENTLIST,
        "contentlist",
        true
      );
      return reducer(state, action);

    case GET_CONTENTDETAIL:
    case GET_CONTENTDETAIL_SUCCESS:
    case GET_CONTENTDETAIL_ERROR:
      const reducer2 = handleAsyncActionsDetail(
        GET_CONTENTDETAIL,
        "contentdetail",
        true
      );
      return reducer2(state, action);
    default:
      return state;
  }
}
