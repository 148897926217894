import React, { useState, useRef, useContext, useEffect } from "react";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { MessagePanel } from "../MessagePanel";
import { NavMenu } from "../NavMenu";
import { PdBodyDesc } from "../PdBodyDesc";
import { PdBodyPriceSide } from "../PdBodyPriceSide";
import { PdBodyPolicy } from "../PdBodyPolicy";
import { PdBodyPrice } from "../PdBodyPrice";
import { PdBodyReview } from "../PdBodyReview";
import { PdBodySimilar } from "../PdBodySimilar";
import { RightMenu } from "../RightMenu";
import { SearchArea } from "../SearchArea";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import DeterminedApi from "../../api/determineds";
import DeterminApi from "../../api_elastic/determins";
import useForm from '../hooks/useForm';

function DeterminDetail({determin}) {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState(determin._source.thumb1);
  const [error, setError] = useState("");
  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': '',
    'content': ''
  });

  const infringeOptions = [
    { value: '', label: 'Select' },
    { value: 'image_copy', label: '이미지도용(모방)' },
    { value: 'image_realbuy', label: '이미지도용(직구)' },
    { value: 'image_theft', label: '이미지도용(공홈)' },
    { value: 'brand_infrange', label: '상표훼손(모자이크)' },
    { value: 'keyword_theft', label: '키워드도용' },
    { value: 'copyright_infrange', label: '저작권침해(캐릭터)' },
    { value: 'genuine', label: '정품' },
  ];
  const [selectedInfringeOption, setSelectedInfringeOption] = useState('');
  const handleSelectInfringeChange = (event) => {
    let value = event.target.value;
    setSelectedInfringeOption(value);
  };

  const refThumbMain = useRef();

  const refProductImage = useRef([]);
  const refProductFile = useRef([]);
  const refDeterminImage = useRef([]);
  const refDeterminFile = useRef([]);

  const refProductImage1 = useRef();
  const refProductFile1 = useRef();
  const refDeterminImage1 = useRef();
  const refDeterminFile1 = useRef();
  const refProductImage2 = useRef();
  const refProductFile2 = useRef();
  const refDeterminImage2 = useRef();
  const refDeterminFile2 = useRef();
  const refProductImage3 = useRef();
  const refProductFile3 = useRef();
  const refDeterminImage3 = useRef();
  const refDeterminFile3 = useRef();
  const refProductImage4 = useRef();
  const refProductFile4 = useRef();
  const refDeterminImage4 = useRef();
  const refDeterminFile4 = useRef();
  const refProductImage5 = useRef();
  const refProductFile5 = useRef();
  const refDeterminImage5 = useRef();
  const refDeterminFile5 = useRef();

  const refSrcName = useRef();
  const refSrcDesc = useRef();
  const refSimilarity = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }

  const onDelete = (_id, e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("mall", determin._source.mall);
    formData.append("determin_code", determin._source.determin_code);
    formData.append("determin_name", determin._source.determin_name);
    formData.append("infringe", "confirmed");

    // 이 수집품은 삭제되었음을 서버에 전송
    DeterminedApi.InsertConfirmed(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        // 엘라스틱에서 이 수집품 데이터 삭제
        const params = { query : { term : { _id : { value : `${_id}` } } } }
        DeterminApi.DeleteDetermin(params).then(response => {
          if(response.status == 200) {
            alert(`수집품 데이터가 삭제되었습니다.`);
            navigate('/determin');
          } else {
            alert(response.data.msg)
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        })
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }  

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    if (determinDesc.length <= 0) {
      const result = alert("침해된 이미지를 선택해 주세요!");
      return false;
    }
    else if (selectedInfringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(formData) {
    DeterminedApi.InsertDetermined(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postDeterminedByClicked(data_list) {
    let formData = new FormData();
    formData.append("mall", determin._source.mall);
    formData.append("category", determin._source.category);
    formData.append("brand", determin._source.brand);
    formData.append("model", determin._source.model);
    formData.append("product_name", determin._source.product_name);
    formData.append("determin_code", determin._source.determin_code);
    formData.append("determin_name", determin._source.determin_name);
    formData.append("product_price", determin._source.product_price);
    formData.append("price_floor", determin._source.price_floor);
    formData.append("determin_price", determin._source.determin_price);
    formData.append("url", determin._source.url.substring(0,250));
    formData.append("similarity", determin._source.title_similarity ? determin._source.title_similarity.toFixed(2) : 0.0);
    formData.append("infringe", selectedInfringeOption);
    formData.append("content", content);
    formData.append("product", isNumeric(determin._source.product_id) ? parseInt(determin._source.product_id) : 0);
    formData.append("seller", determin._source.seller);
    formData.append("seller_home", determin._source.seller_home);
    let downloadedCount = 0;
    data_list.map((data, index, total) => {
      fetch(data.determin_image)
        .then((response) => response.blob())
        .then((blob) => {
          downloadedCount += 1;
          let now = (new Date()).getTime()+index;
          let determin_file = 'determin_' + now + '.' + data.determin_image.substring(data.determin_image.lastIndexOf('.') + 1);
          formData.append(`determin_image${index+1}`, new File([blob], determin_file));
          data.determin_image = determin_file; // data.product_image.substring(0,data.product_image.lastIndexOf('/')+1) + determin_file;
          formData.append("determins", JSON.stringify(data_list));
          if (downloadedCount === data_list.length) {
            InsertDeterminedData(formData);
          }
        })
        .catch((error) => {
          downloadedCount += 1;
          console.error("Error fetching the file:", error);
        });
      });
  }

  const onSubmit = e => {
    e.preventDefault();
    if (validateInputs() == true) {
      let data_list = [];
      determin._source.product_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      determin._source.official_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      determin._source.determined_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      if (data_list.length > 0) {
          postDeterminedByClicked(data_list);
      }
    }  
  }
  
  const onImageCheck = (itemname, param_sim, e) => {
    e.preventDefault();
    let desc = '';
    determin._source.product_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    determin._source.official_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    determin._source.determined_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    // 화면상에 침해 내용 출력
    setDeterminDesc(desc);
  }

  const onContentChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    console.log(value);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(thumburl)
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }
  
  return (
    <div className="determin-detail">
      <div className="div-2">

        {/* // 1.FooterPanel의 className을 "footer-panel-instance"로, 2.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/>

        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <KakaoService className="design-component-instance-node-2" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <SearchArea className="search-area-instance" />
        <RightMenu className="right-menu-instance" />
        <div className="main-frame" id="mainbody">
          <div className="section-frame">
            <div className="frame">
              <div className="frame-2">
                <div className="text-wrapper-15">플랫폼 : {determin._source.mall}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">카테고리 : {determin._source.category}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-17">{determin._source.determin_name ? determin._source.determin_name.substring(0,100) : null}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">{determin._source.brand} / {determin._source.model}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">위조품 판매가 : {determin._source.determin_price} ({determin._source.currency ? determin._source.currency : null})</div>
              </div>

              <div className="frame-2">
                <a className="text-wrapper-16" href={determin._source.seller_home} target="blank" style={{"color": "blue"}}>판매자 : {determin._source.seller} Home</a>
              </div>

              <div className="frame-2">
                <a className="text-wrapper-16" href={determin._source.url} target="blank" style={{"color": "blue"}}>위조품 상세보기</a>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">수집형태 : {determin._source.searchType}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">이미지유사도 : {image_similarity_string(determin._source.product_similar)}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">공홈유사도 : {image_similarity_string(determin._source.official_similar)}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">판별품유사도 : {image_similarity_string(determin._source.determined_similar)}</div>
              </div>

              <div className="frame-2">
                <div className="text-wrapper-16">수집일시 : {determin._source.created_at}</div>
              </div>

            </div>

            <div className="frame-3">
              <div className="img-wrapper">
                <div style={{backgroundImage: `url(${thumbClickedUrl})`, backgroundSize:'cover' }}
                  className="div-image-2" src={null}
                   ref={refThumbMain}
                />
              </div>

              <div className="frame-4">
              { 
                [determin._source.thumb1, determin._source.thumb2, determin._source.thumb3, determin._source.thumb4, determin._source.thumb5].map((thumburl, index, total) => (
                  thumburl && thumburl.length > 0 ?
                  <div className={`frame-${index+5}`} style={{cursor:'pointer', backgroundImage: `url(${thumburl})`, backgroundSize:'contain', borderWidth: 2,borderStyle: 'solid'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                  : null
                ))
              }
              </div>
            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-10">
              <div className="text-wrapper-18">타이틀유사도</div>
            </div>

            <div className="frame-11">
              <div className="frame-12">
                <div className="text-wrapper-19">정품 제목</div>
              </div>

              <div className="frame-13">
                <div className="text-wrapper-19">{determin._source.product_name}</div>
              </div>

              <div className="frame-13">
                <div className="text-wrapper-19">위조품 제목</div>
              </div>

              <div className="frame-13">
                <div className="text-wrapper-19">{determin._source.determin_name.substring(0,50)}</div>
              </div>
            </div>

            <div className="frame-11">
              <div className="frame-14">
                <div className="text-wrapper-19">브랜드/모델</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-19">{determin._source.brand} / {determin._source.model}</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-19">타이틀 유사도</div>
              </div>
              <div className="frame-15">
                <div className="text-wrapper-19">{determin._source.title_similarity ? determin._source.title_similarity.toFixed(2) : null}</div>
              </div>

            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-16">
              <div className="text-wrapper-18">가격 유사도({determin._source.currency ? determin._source.currency : null})</div>
            </div>

            <div className="frame-11">
              <div className="frame-12">
                <div className="text-wrapper-19">정품 기준가</div>
              </div>

              <div className="frame-13">
                <div className="text-wrapper-19">{determin._source.product_price ? determin._source.product_price.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-13">
                <div className="text-wrapper-19">위조품 판매가</div>
              </div>

              <div className="frame-13">
                <div className="text-wrapper-19">{determin._source.determin_price ? determin._source.determin_price.toLocaleString('ko-KR') : null}</div>
              </div>
            </div>

            <div className="frame-11">
              <div className="frame-14">
                <div className="text-wrapper-19">정품 하한가</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-19">{determin._source.price_floor ? determin._source.price_floor.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-19">정품 하한가 기준</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-19">{(determin._source.determin_price - determin._source.price_floor).toLocaleString('ko-KR')}</div>
              </div>
            </div>
          </div>

          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-18">정품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-3">

            { determin._source.product_similar && determin._source.product_similar.map((sim, index, total) => (
              <div className="rec-item-frame" key={index}>
                <div className="div-3">
                  <div className="thumbnail">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        <img className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} />
                      </div>
                      <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('image', sim, e)}}>
                        <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                      </button>
                    </div>
                  </div>
                  <div className="info-frame">
                    <div className="frame-11">
                      <div className="frame-14">
                        <div className="text-wrapper-21">{sim.src_name}</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">{sim.src_desc}</div>
                      </div>
                    </div>
                    <div className="frame-11">
                      <div className="frame-14">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                    </div>
                    <div className="frame-16" />
                  </div>
                </div>
                <div className="div-3">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/>
                      </div>
                      <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('image', sim, e)}}>
                        <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                      </button>
                    </div>
                  </div>
                  <div className="info-frame">
                    <div className="frame-11">
                      <div className="frame-15">
                        <div className="text-wrapper-21">similarity</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                      </div>
                    </div>
                    <div className="frame-11">
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                    </div>
                    <div className="frame-16" />
                  </div>
                </div>
              </div>
            ))}

          </div>


          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-18">공홈 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-3">

          { determin._source.official_similar && determin._source.official_similar.map((sim, index, total) => (
              <div className="rec-item-frame" key={index}>
                <div className="div-3">
                  <div className="thumbnail">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        <img className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} />
                      </div>
                      <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('official', sim, e)}}>
                        <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                      </button>
                    </div>
                  </div>
                  <div className="info-frame">
                    <div className="frame-11">
                      <div className="frame-14">
                        <div className="text-wrapper-21">{sim.src_name}</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">{sim.src_desc}</div>
                      </div>
                    </div>
                    <div className="frame-11">
                      <div className="frame-14">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                    </div>
                    <div className="frame-16" />
                  </div>
                </div>
                <div className="div-3">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/>
                      </div>
                      <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('official', sim, e)}}>
                        <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                      </button>
                    </div>
                  </div>
                  <div className="info-frame">
                    <div className="frame-11">
                      <div className="frame-15">
                        <div className="text-wrapper-21">similarity</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                      </div>
                    </div>
                    <div className="frame-11">
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                    </div>
                    <div className="frame-16" />
                  </div>
                </div>
              </div>
            ))}

          </div>


          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-18">판별품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-3">

          { determin._source.determined_similar && determin._source.determined_similar.map((sim, index, total) => (
              <div className="rec-item-frame" key={index}>
                <div className="div-3">
                  <div className="thumbnail">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        <img className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} />
                      </div>
                      <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('determined', sim, e)}}>
                        <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                      </button>
                    </div>
                  </div>
                  <div className="info-frame">
                    <div className="frame-11">
                      <div className="frame-14">
                        <div className="text-wrapper-21">{sim.src_name}</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">{sim.src_desc}</div>
                      </div>
                    </div>
                    <div className="frame-11">
                      <div className="frame-14">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                    </div>
                    <div className="frame-16" />
                  </div>
                </div>
                <div className="div-3">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/>
                      </div>
                      <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('determined', sim, e)}}>
                        <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                      </button>
                    </div>
                  </div>
                  <div className="info-frame">
                    <div className="frame-11">
                      <div className="frame-15">
                        <div className="text-wrapper-21">similarity</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                      </div>
                    </div>
                    <div className="frame-11">
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                      <div className="frame-15">
                        <div className="text-wrapper-21">&nbsp;</div>
                      </div>
                    </div>
                    <div className="frame-16" />
                  </div>
                </div>
              </div>
            ))}

          </div>

          <form className="section-frame-4" name="frm" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="form-title-frame">
              <div className="form-title">판별하기</div>
            </div>

            <div className="form-caption-frame">
              <div className="form-caption-wrapper">
                <div className="form-caption">판별 : {determinDesc}</div>
              </div>

              <div className="form-determ-tag-wrapper">
                <div className="text-wrapper-20">침해 사유</div>
              </div>

              <select className="form-determ-drop-wrapper" id="infringe" name="infringe" value={selectedInfringeOption} onChange={handleSelectInfringeChange}>
                { infringeOptions.map((option, index, total) => ( 
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>

            </div>

            <div className="form-text-frame">
              <textarea className="form-text" name="content" value={content} style={{border:"none"}} onChange={onChange}></textarea>
            </div>
            <div className="form-submit-frame">
              <div className="form-submit-layout" style={{cursor:'pointer'}} onClick={(e)=>{onDelete(determin._id, e)}}>
                <div className="form-submit">삭&nbsp;&nbsp;제</div>
              </div>
              <div className="form-submit-wrapper" style={{cursor:'pointer'}} onClick={onSubmit}>
                <div className="form-submit-2">저&nbsp;&nbsp;장</div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default DeterminDetail;