import * as determinApi from "../api_elastic/determins";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_DETERMINLIST = "GET_DETERMINLIST";
const GET_DETERMINLIST_SUCCESS = "GET_DETERMINLIST_SUCCESS";
const GET_DETERMINLIST_ERROR = "GET_DETERMINLIST_ERROR";

// 상품 항목 조회하기
const GET_DETERMINDETAIL = "GET_DETERMINDETAIL";
const GET_DETERMINDETAIL_SUCCESS = "GET_DETERMINDETAIL_SUCCESS";
const GET_DETERMINDETAIL_ERROR = "GET_DETERMINDETAIL_ERROR";

const CLEAR_DETERMINTLIST = "CLEAR_DETERMINTLIST";

export const getDeterminList = (data) => ({
  type: GET_DETERMINLIST,
  payload: { data: data },
  meta: data.size,
});

export const getDeterminDetail = (data) => ({
  type: GET_DETERMINDETAIL,
  payload: { data: data },
  meta: data.query.term._id.value,
});

const getDeterminListSaga = createPromiseSagaList(
  GET_DETERMINLIST,
  determinApi.getDeterminList
);

const getDeterminDetailSaga = createPromiseSagaDetail(
  GET_DETERMINDETAIL,
  determinApi.getDeterminDetail
);

// 사가들을 합치기
export function* determinSaga() {
  yield takeEvery(GET_DETERMINLIST, getDeterminListSaga);
  yield takeEvery(GET_DETERMINDETAIL, getDeterminDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  determinlist: reducerUtils.initial(),
  determindetail: reducerUtils.initial(),
};

export default function determinReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DETERMINLIST:
    case GET_DETERMINLIST_SUCCESS:
    case GET_DETERMINLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_DETERMINLIST,
        "determinlist",
        true
      );
      return reducerList(state, action);

    case GET_DETERMINDETAIL:
    case GET_DETERMINDETAIL_SUCCESS:
    case GET_DETERMINDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_DETERMINDETAIL,
        "determindetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_DETERMINTLIST:
      return {
        ...state,
        determinlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
