import React, { useState, useRef, useContext, useEffect } from "react";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { MessagePanel } from "../MessagePanel";
import { NavMenu } from "../NavMenu";
import { PdBodyDesc } from "../PdBodyDesc";
import { PdBodyPriceSide } from "../PdBodyPriceSide";
import { PdBodyPolicy } from "../PdBodyPolicy";
import { PdBodyPrice } from "../PdBodyPrice";
import { PdBodyReview } from "../PdBodyReview";
import { PdBodySimilar } from "../PdBodySimilar";
import { RightMenu } from "../RightMenu";
import { SearchArea } from "../SearchArea";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import ForgeryApi from "../../api/forgerys";
import useForm from '../hooks/useForm';

function ForgeryDetail({prd}) {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);  

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState((prd && prd.determin_image1) ? BACKEND_SERVER_BASEURL + prd.determin_image1 : null );
  const [ imageChecked, setImageChecked ] = useState(false);
  const [error, setError] = useState("");

  // 판별 결과 JSON
  let dtms = JSON.parse(prd.determins);

  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': '',
    'content': ''
  });

  const infringeOptions = [
    { value: '', label: 'Select' },
    { value: 'image_copy', label: '이미지도용(모방)' },
    { value: 'image_realbuy', label: '이미지도용(직구)' },
    { value: 'image_theft', label: '이미지도용(공홈)' },
    { value: 'brand_infrange', label: '상표훼손(모자이크)' },
    { value: 'keyword_theft', label: '키워드도용' },
    { value: 'copyright_infrange', label: '저작권침해(캐릭터)' },
    { value: 'genuine', label: '정품' },
  ];
  const [selectedInfringeOption, setSelectedInfringeOption] = useState('');
  const handleSelectInfringeChange = (event) => {
    let value = event.target.value;
    setSelectedInfringeOption(value);
  };

  // 출처
  const origins = {
    'product': '정상품',
    'official': '공홈',
    'determined': '기판별품',
  }  

  const refThumbMain = useRef();
  const refProductImage = useRef();
  const refProductFile = useRef();
  const refDeterminImage = useRef();
  const refDeterminFile = useRef();
  const refSrcName = useRef();
  const refSrcDesc = useRef();
  const refSimilarity = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }

  const onDelete = (_id, e) => {
    e.preventDefault();

    // 위조품 데이터 재거 서버에 전송
    let formData = new FormData();
    formData.append("id", _id);
    ForgeryApi.DeleteForgery(_id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`위조품 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    if (determinDesc.length <= 0) {
      const result = alert("침해된 이미지를 선택해 주세요!");
      return false;
    }
    else if (selectedInfringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(formData) {
    DeterminedApi.InsertDetermined(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postDeterminedByClicked() {
    let formData = new FormData();
    formData.append("mall", prd.mall);
    formData.append("category", prd.category);
    formData.append("determin_code", prd.determin_code);
    formData.append("determin_name", prd.determin_name);
    formData.append("product_price", prd.product_price);
    formData.append("price_floor", prd.price_floor);
    formData.append("determin_price", prd.determin_price);
    formData.append("url", prd.url);
    formData.append("src_name", refSrcName.current);
    formData.append("src_desc", refSrcDesc.current);
    formData.append("similarity", (refSimilarity.current).toFixed(2));
    formData.append("infringe", selectedInfringeOption);
    formData.append("content", content);
    formData.append("product", isNumeric(prd.product_id) ? parseInt(prd.product_id) : 0);
    formData.append("seller", prd.seller);
    formData.append("seller_home", prd.seller_home);

    // Product Image Download 
    let downloadedCount = 0;
    if (refProductImage.current.length > 0) {
      let productImage = refProductImage.current
      productImage = productImage.substring(productImage.lastIndexOf('media/')+6);
      formData.append("product_image", productImage);
      downloadedCount += 1;
      if (downloadedCount === 2) 
        InsertDeterminedData(formData);
    } else {
      downloadedCount += 1;
      if (downloadedCount === 2) 
        InsertDeterminedData(formData);
    }

    // Determin Image Download 
    if (refDeterminImage.current.length > 0) {
      fetch(refDeterminImage.current)
        .then((response) => response.blob())
        .then((blob) => {
          formData.append("determin_image", new File([blob], refDeterminFile.current));
          downloadedCount += 1;
          if (downloadedCount === 2) 
            InsertDeterminedData(formData);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
          downloadedCount += 1;
          if (downloadedCount === 2) 
            InsertDeterminedData(formData);
        });
    } else {
      downloadedCount += 1;
      if (downloadedCount === 2) 
        InsertDeterminedData(formData);
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    if (validateInputs() == true) {
      prd.product_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          refProductImage.current = sim.product;
          refProductFile.current = sim.product_file;
          refDeterminImage.current = sim.determin;
          refDeterminFile.current = sim.determin_file;
          refSrcName.current = sim.src_name;
          refSrcDesc.current = sim.src_desc;
          refSimilarity.current = sim.similarity;
          postDeterminedByClicked();
        }
      })
      prd.official_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          refProductImage.current = sim.product;
          refProductFile.current = sim.product_file;
          refDeterminImage.current = sim.determin;
          refDeterminFile.current = sim.determin_file;
          refSrcName.current = sim.src_name;
          refSrcDesc.current = sim.src_desc;
          refSimilarity.current = sim.similarity;
          postDeterminedByClicked();
        }
      })
      prd.determined_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          refProductImage.current = sim.product;
          refProductFile.current = sim.product_file;
          refDeterminImage.current = sim.determin;
          refDeterminFile.current = sim.determin_file;
          refSrcName.current = sim.src_name;
          refSrcDesc.current = sim.src_desc;
          refSimilarity.current = sim.similarity;
          postDeterminedByClicked();
        }
      })

    }  
  }
  
  const onImageCheck = (itemname, param_sim, e) => {
    e.preventDefault();
    let desc = '';
    let date = new Date();
    let tm = date.getTime();
    prd.product_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
          // if (infringe.length > 0)
          //   desc += '/' + infringe;
      // } else {
      //   sim.checked = false
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.official_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;

          // // 선택한 내용 화면 출력
          // desc = sim.src_name;
          // if (sim.src_desc&&sim.src_desc.length > 0) 
          //   desc += ' / ' + sim.src_desc;
          // desc += ' / ' + sim.similarity.toFixed(2);
          // if (infringe.length > 0)
          //   desc += ' / ' + infringe;
        }
      // } else {
      //   sim.checked = false
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.determined_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;

          // // 선택한 내용 화면 출력
          // desc = sim.src_name;
          // if (sim.src_desc&&sim.src_desc.length > 0) 
          //   desc += ' / ' + sim.src_desc;
          // desc += ' / ' + sim.similarity.toFixed(2);
          // if (infringe.length > 0)
          //   desc += ' / ' + infringe;
        }
      // } else {
      //   sim.checked = false
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    setImageChecked(!imageChecked);
    setDeterminDesc(desc);
  }

  const onContentChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    console.log(value);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(BACKEND_SERVER_BASEURL + thumburl)
  }

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }
  
  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="forgery-detail">
      <div className="div-3">
        {/* // 1.FooterPanel의 className을 "footer-panel-instance"로, 2.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-2" bodyBottom={bodyBottom}/> 

        <JimmedProducts className="design-component-instance-node-4" />
        <ViewedProducts className="design-component-instance-node-4" />
        <KakaoService className="design-component-instance-node-5" />
        <LinkTree className="link-tree-2" />
        <NavMenu className="design-component-instance-node-5" />
        <MainLogo className="main-logo-2" />
        <SearchArea className="search-area-instance" />
        <RightMenu className="right-menu-2" />
        <div className="main-frame" id="mainbody">
          <div className="section-frame">
            <div className="frame-2">
              <div className="frame-3">
                <div className="text-wrapper-26">플랫폼 : {prd.mall}</div>
              </div>

              <div className="frame-3">
                <div className="text-wrapper-27">카테고리 : {prd.category}</div>
              </div>

              <div className="frame-4">
                <p className="p">
                  {prd.determin_name ? prd.determin_name.substring(0,100) : null}
                </p>
              </div>
              <div className="frame-3">
                <div className="text-wrapper-27">화폐단위 : {prd.currency}</div>
              </div>

              <div className="frame-3">
                <div className="text-wrapper-27">정상품 판매가 : {prd.product_price}</div>
              </div>

              <div className="frame-3">
                <div className="text-wrapper-27">정상품 하한가 : {prd.price_floor}</div>
              </div>

              <div className="frame-3">
                <div className="text-wrapper-27">위조품 판매가 : {prd.determin_price}</div>
              </div>

              <div className="frame-3">
                <div className="text-wrapper-27">침해유형 : {prd.infringe}</div>
              </div>

              <div className="frame-3">
                <a className="text-wrapper-27" href={prd.seller_home} target="blank" style={{"color": "blue"}}>판매자 : {prd.seller} 홈</a>
              </div>

              <div className="frame-3">
                <a className="text-wrapper-27" href={prd.url} target="blank" style={{"color": "blue"}}>위조품 상세보기</a>
              </div>

              <div className="frame-3">
                <div className="text-wrapper-27">수집일시 : {prd.created_at}</div>
              </div>
            </div>

            <div className="frame-5">
              <div className="img-wrapper">
                <div className="div-image-2" style={{backgroundImage: `url(${thumbClickedUrl})`, backgroundSize:'cover' }}  ref={refThumbMain}/>
              </div>

              <div className="frame-6">
              { 
                [prd.determin_image1, prd.determin_image2, prd.determin_image3, prd.determin_image4, prd.determin_image5].map((thumburl, index, total) => (
                  thumburl && thumburl.length > 0 ?
                  <div className={`frame-${index+7}`} style={{cursor:'pointer', backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumburl})`, backgroundSize:'contain', borderColor:'#dddddd', borderWidth: 2,borderStyle: 'solid'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                  : null
                ))
              }
              </div>
            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-12">
              <div className="text-wrapper-28">타이틀유사도</div>
            </div>

            <div className="frame-13">
              <div className="frame-14">
                <div className="text-wrapper-29">정품 제목</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-29">{prd.product_name}</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-29">위조품 제목</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-29">{prd.determin_name.substring(0,60)}</div>
              </div>
            </div>

            <div className="frame-13">
              <div className="frame-16">
                <div className="text-wrapper-29">브랜드/모델</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-29">{prd.brand} / {prd.model}</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-29">타이틀 유사도</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-29">{prd.similarity}</div>
              </div>
            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-18">
              <div className="text-wrapper-28">가격 유사도({prd.currency ? prd.currency : null})</div>
            </div>

            <div className="frame-13">
              <div className="frame-14">
                <div className="text-wrapper-29">정품 기준가</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-29">{prd.product_price ? prd.product_price.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-29">위조품 하한가</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-29">{prd.determin_price ? prd.determin_price.toLocaleString('ko-KR') : null}</div>
              </div>
            </div>

            <div className="frame-13">
              <div className="frame-16">
                <div className="text-wrapper-29">정품 하한가</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-29">{prd.price_floor ? prd.price_floor.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-29">정품 하한가 기준</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-29">{(prd.determin_price - prd.price_floor).toLocaleString('ko-KR')}</div>
              </div>
            </div>
          </div>

          <div className="section-frame-3">
            <div className="frame-3">
              <div className="text-wrapper-28">이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-4">

            { dtms.map((dtm, index, total) => ( 
            <div className="rec-item-frame">

              <div className="div-4">
                <div className="thumbnail">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img className="back-image" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${dtm.product_image})`, backgroundSize:'contain'}} />
                    </div>
                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-29">{dtm.src_name}</div>
                    </div>
                    <div className="frame-17">
                      <div className="text-wrapper-29">{dtm.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-29">&nbsp;</div>
                    </div>
                    <div className="frame-17">
                      <div className="text-wrapper-29">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-20" />
                </div>

              </div>

              <div className="div-4">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img className="back-image" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${dtm.determin_image})`, backgroundSize:'contain'}} />
                    </div>
                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-29">similarity</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-29">{dtm.similarity ? parseFloat(dtm.similarity).toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-29">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-29">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>

            </div>


            ))}

            
          </div>

          <div className="section-frame-5">
            <div className="form-title-frame">
              <div className="form-title">판별하기</div>
            </div>

            <div className="form-caption-frame">
              <div className="form-caption-wrapper">
                <div className="form-caption">판별 내용</div>
              </div>

              <div className="form-determ-tag-wrapper">
                <div className="text-wrapper-30">침해 사유</div>
              </div>

              <div className="form-determ-drop-wrapper">
                <div className="text-wrapper-30">{prd.infringe}</div>
              </div>
            </div>

            <div className="form-text-frame">
              <div className="form-text">{prd.content}</div>
            </div>

            <div className="form-submit-frame" >
              <div className="form-submit-layout" style={{cursor:'pointer'}} onClick={(e) => onDelete(prd.id, e)}>
                <div className="form-submit">삭&nbsp;&nbsp;제</div>
              </div>

              <div className="form-submit-wrapper" style={{cursor:'pointer'}} onClick={() => onBackward()}>
                <div className="form-submit-2">돌아가기</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgeryDetail;