import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LeftMenu } from "../../components/LeftMenu";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
// import DatePicker from 'react-datepicker';
import { DatePickerInput } from "../../components/DatePickerInput";
import { Input } from "../../components/Input";

export const ProductList = ({ dataParam, currPage, onCategorySubmit, onPageSubmit, onSearchSubmit }) => {  
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const refToDate = useRef();
  // 지재권유형
  const iprTypes = {brand: '상표', copyright: '저작권', design: '디자인', patent: '특허'};

  // 진행상황  
  const categoryOptions = [
    { value: 'all', label: '모두' },
    { value: 'beauty', label: '화장품' },
    { value: 'clothes', label: '의류' },
    { value: 'bags', label: '가방' },
    { value: 'appliance', label: '가전' },
    { value: 'food', label: '음식료' },
    { value: 'furniture', label: '가구' },
    { value: 'infant', label: '아동/유아' },
    { value: 'contents', label: '컨텐츠' },
    { value: 'leasure', label: '레저' },
    { value: 'character', label: '캐릭터' },
    { value: 'medical', label: '의료기기' },
  ];  
  const categories = {
    'beauty': '화장품',
    'clothes': '의류',
    'bags': '가방',
    'appliance': '가전',
    'food': '음식료',
    'furniture': '가구',
    'infant': '아동/유아',
    'contents': '컨텐츠',
    'leasure': '레저',
    'character': '캐릭터',
    'medical': '의료기기',
  }
  const [category, setCategory] = useState('');
  const handleCategory = (e) => {
    let value = e.target.value;
    setCategory(value);
    onCategorySubmit(value);
  };

  // 세부검색
  const searchOptions = [
    { value: 'all', label: '전체' },
    { value: 'product_name', label: '제품명' },
    { value: 'keywords', label: '키워드' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="product-list">
      <div className="div-3">
        <div className="body-wrapper" id="mainbody">
          <div className="body-2">
            <div className="title-wrapper">
              <div className="title-2">정품정보관리</div>
            </div>

            <div className="body-sub-title-2" />

            <div className="body-head-2">
              <div className="drop-downs-2">
                <div className="dropdown-3">
                  <div className="text-wrapper-27">카테고리</div>
                </div>

                <div className="dropdown-4">
                  <select className="text-wrapper-28" style={{width:"95px", height:"26px", border:"none"}} id="category" name="category" value={category} onChange={handleCategory}>
                    { categoryOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>

              </div>

              {pageInfo ?
              <BodySearch className="design-component-instance-node-4" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>

            <div className="form-table-wrapper">
              <div className="form-table-2">

                <div className="head-frame-2">
                  <div className="body-row-cell-5">
                    <div className="text-wrapper-29">번호</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-30">이미지</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-30">브랜드</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-29">모델</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-29">카테고리</div>
                  </div>

                  <div className="body-row-cell-7">
                    <div className="text-wrapper-31">제품명</div>
                  </div>

                  <div className="body-row-cell-8">
                    <div className="text-wrapper-29">키워드</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-29">최종가격</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-29">정품하한</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-29">거래처</div>
                  </div>

                  <div className="body-row-cell-6">
                    <div className="text-wrapper-29">생성일</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame-2" to={`/productdetail/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>
                  <div className="body-row-data-6">
                    <div className="text-wrapper-32">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-7">
                    <div className="text-wrapper-32">{prd.thumb1}</div>
                  </div>

                  <div className="body-row-data-7">
                    <div className="text-wrapper-32">{prd.brand}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-32">{prd.model}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-33">{categories[prd.category]}</div>
                  </div>

                  <div className="body-row-data-9">
                    <div className="text-wrapper-32">{prd.product_name.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-10">
                    <div className="text-wrapper-32">{prd.keywords.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-32">{prd.final_price.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-32">{prd.price_floor.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-32">{prd.cust_name}</div>
                  </div>

                  <div className="body-row-data-8">
                    <div className="text-wrapper-32">{prd.created_at ? prd.created_at.substr(0,10) : null}</div>
                  </div>
                </Link>
                )) : <></>}
                
              </div>
            </div>

            {/* <BodyPage className="design-component-instance-node-4" /> */}
            {pageInfo ?
              <BodyPage className="body-page-2" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}


          </div>
        </div>

        {/* // 1.FooterPanel의 className을 "footer-panel-instance"로, 2.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/> 

        <JimmedProducts className="design-component-instance-node-5" />
        <ViewedProducts className="design-component-instance-node-5" />
        <LeftMenu className="left-menu-2" />
        <KakaoService className="design-component-instance-node-6" />
        <LinkTree className="link-tree-2" />
        <NavMenu className="design-component-instance-node-6" />
        <MainLogo className="main-logo-2" />
        <div className="search-area-3">
          <div className="input-2">
            <div className="input-box-3">검색어를 입력해주세요</div>
          </div>

          <div className="search-3">
            <div className="text-wrapper-34"></div>
          </div>
        </div>

        <RightMenu className="right-menu-2" />
      </div>
    </div>
  );
};
