import React from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MessagePanel } from "../../components/MessagePanel";
import { NavMenu } from "../../components/NavMenu";
import { PdBodyDesc } from "../../components/PdBodyDesc";
import { PdBodyPolicy } from "../../components/PdBodyPolicy";
import { PdBodyPrice } from "../../components/PdBodyPrice";
import { PdBodyPriceSide } from "../../components/PdBodyPriceSide";
import { PdBodyReview } from "../../components/PdBodyReview";
import { PdBodySimilar } from "../../components/PdBodySimilar";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

function CollectDetail({product}) {

  return (
    <div className="collect-detail">
      <div className="div-2">
        <FooterPanel className="footer-panel-instance" />
        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <KakaoService className="design-component-instance-node-2" />
        <PdBodySimilar className="PD-body-similar-instance" />
        <div className="overlap">
          <MessagePanel className="message-panel-instance" />
          <div className="product-detail-body">
            <div className="div-item-wrap">
              <div className="PD-body-thumbnail">
                <div className="div-item-rate">
                  <div className="div-stars-2">
                    <div className="symbol-13"></div>
                    <div className="symbol-13"></div>
                    <div className="symbol-13"></div>
                    <div className="symbol-13"></div>
                    <div className="symbol-13"></div>
                  </div>
                  <div className="text-wrapper-43">4.9</div>
                  <div className="p-count">
                    <div className="text-wrapper-44">(56개의 평가)</div>
                  </div>
                </div>
                <div className="div-image-2">
                  <div className="div-3" />
                </div>
              </div>
              <PdBodyPriceSide
                className="PD-body-price-side-instance"
                linkClassName="design-component-instance-node-3"
              />
              <div className="PD-body-tab">
                <div className="div-container">
                  <div className="list-3">
                    <div className="item-margin">
                      <div className="item-link-10">
                        <div className="text-wrapper-45">상품 소개</div>
                      </div>
                    </div>
                    <div className="item-link-wrapper">
                      <div className="item-link-11">
                        <div className="text-wrapper-46">가격 정보</div>
                      </div>
                    </div>
                    <div className="item-margin-2">
                      <div className="item-link-11">
                        <div className="text-wrapper-46">판매 정책</div>
                      </div>
                    </div>
                    <div className="item-margin-3">
                      <div className="item-link-11">
                        <div className="text-wrapper-47">상품 리뷰</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PdBodyDesc className="design-component-instance-node-4" />
              <PdBodyPrice
                className="PD-body-price-instance"
                divVisibleLgClassName="PD-body-price-2"
                linkClassName="design-component-instance-node-3"
              />
              <PdBodyPolicy className="design-component-instance-node-4" />
              <PdBodyReview className="PD-body-review-instance" />
            </div>
          </div>
        </div>
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <SearchArea className="search-area-instance" />
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
export default CollectDetail;