import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubscribingList } from "../components/SubscribingList";
import { getSubscribingList } from "../modules/subscribings";
import { useAuth } from "../contexts/auth/auth.context";

export const SubscribingListContainer = ({mallParam = 'all', searchParam = 'all', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const [ mall, setMall ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(1);

  const { data, loading, error} = useSelector((state) => state.subscribingReducer.subscribinglist);
  const dispatch = useDispatch();

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParm, searchParm) => {
    setPage(1);
    setSearch(searchParm);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getSubscribingList(mall, search, page));
  }, [dispatch, mall, search, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <SubscribingList 
    dataParam={data}
    currPage = {page}
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

