import axios from "./index";

class ReportApi {
  static getReportList = async (params) => {
    const response = await axios.get("/reports/", { params: params });
    return response.data.detail;
  };

  static getReportDetail = async (params) => {
    const response = await axios.get(`/reports/${params.id}/`);
    return response.data;
  };

  static postReportCreate = (formData) => {
    return axios.post(`reportcreate/`, formData);
  };

}

export default ReportApi;
