import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ForgeryList } from "../components/ForgeryList";
import { getForgeryList, clearForgeryList } from "../modules/forgerys";
import { useAuth } from "../contexts/auth/auth.context";

export const ForgeryListContainer = ({platformParam = '', categoryParam = '', infringeParam = '', searchTypeParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ platform, setPlatform ] = useState(platformParam);
  const [ category, setCategory ] = useState(categoryParam);
  const [ infringe, setInfringe ] = useState(infringeParam);
  const [ searchType, setSearchType ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.forgeryReducer.forgerylist);
  const dispatch = useDispatch();

  const onPlatformSubmit = (platformParm) => {
    setPage(1);
    setPlatform(platformParm);
  }

  const onCategorySubmit = (categoryParm) => {
    setPage(1);
    setCategory(categoryParm);
  }

  const onInfringeSubmit = (infringeParm) => {
    setPage(1);
    setInfringe(infringeParm);
  }

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParm, searchParm) => {
    setPage(1);
    setSearchType(searchTypeParm);
    setSearch(searchParm);
  }

  // 컴포넌트 마운트 후 목록 요청
  useEffect(() => {
    dispatch(getForgeryList(platform, category, infringe, searchType, search, page));
  }, [dispatch, platform, category, infringe, searchType, search, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <ForgeryList 
    dataParam={data}
    currPage = {page}
    onPlatformSubmit={onPlatformSubmit} 
    onCategorySubmit={onCategorySubmit} 
    onInfringeSubmit={onInfringeSubmit} 
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

