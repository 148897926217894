import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const BodySearch = ({ className, 
  optionsClassName='optionsClassName',
  inputClassName='inputClassName',
  buttonClassName='buttonClassName',
  searchOptions, onSearchSubmit  }) => {
  // const { totalCount, currPage, next, prev } = pageInfo;             // 비구조화 할당

  const [selectedSearchOption, setSelectedSearchOption] = useState('');
  const handleSelectSearchChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const [formData, setFormData] = useState({
    "searchType": "",
    "search": ""
  });

  const { searchType, search } = formData; 

  const onChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });

  };

  const onKeyDown = (e) => {
    if(e.key === "Enter" && search != "") {
      if (search != "") {
        onSearchSubmit(searchType, search);
      } 
    }
  } 
  
  const onClick = e => {
    e.preventDefault();
    onSearchSubmit(searchType, search);
  } 
  return (
    <div className={`body-search ${className}`}>

      <select className={`options ${optionsClassName}`} style={{height:"28px"}} id="searchType" name="searchType" value={searchType} onChange={handleSelectSearchChange}>
        { searchOptions.map((option, index, total) => ( 
          <option className="text-wrapper-2" value={option.value}>{option.label}</option>
        ))}
      </select>


      <input className="input text-wrapper-3" type="text" name='search' value={search} onChange={onChange} onKeyDown={(e) => onKeyDown(e)} />
      
      <button className={`button ${buttonClassName}`} style={{cursor:'pointer'}} onClick={onClick}>
        <div className={`text-wrapper-4`}>검색</div>
      </button>
    </div>
  );
};

BodySearch.propTypes = {
  inputType: PropTypes.string,
};
