import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LeftMenu } from "../../components/LeftMenu";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
// import DatePicker from 'react-datepicker';
import { DatePickerInput } from "../../components/DatePickerInput";
import { Input } from "../../components/Input";

export const LawsuitList = ({ dataParam, currPage, onPlatformSubmit, onProgressSubmit, onToDateSubmit, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const summary = dataParam ? dataParam.summary : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const refToDate = useRef();
  // 지재권유형
  const iprTypes = {brand: '상표', copyright: '저작권', design: '디자인', patent: '특허'};

  // 플랫폼  
  const platformOptions = [
    { value: 'all', label: '모두' },
    { value: 'alibaba', label: '알리바바' },
    { value: 'temu', label: '태무' },
    { value: '1688', label: '일육팔팔' },
    { value: 'taobao', label: '타오바오' },
    { value: 'lazada', label: '라자다' },
    { value: 'amazon', label: '아마존' },
    { value: 'shopee', label: '쇼피' },
    { value: 'naver', label: '네이버쇼핑' },
    { value: 'coupang', label: '쿠팡' },
  ];  
  const platforms = {
    'alibaba': '알리바바',
    'temu': '태무',
    '1688': '일육팔팔',
    'taobao': '타오바오',
    'lazada': '라자다',
    'amazon': '아마존',
    'shopee': '쇼피',
    'naver': '네이버쇼핑',
    'coupang': '쿠팡',
  }
  const [platform, setPlatform] = useState('all');
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  // 진행상황  
  const progressOptions = [
    { value: 'all', label: '모두' },
    { value: 'notary_ready', label: '공증준비' },
    { value: 'indict_submit', label: '공소장제출' },
    { value: 'indict_confirm', label: '소송주체확인' },
    { value: 'attorney_filing', label: '변호사입건대기' },
    { value: 'pre_negotation', label: '소송전협상' },
    { value: 'legal_wait', label: '법정개정대기' },
    { value: 'first_wait', label: '1심판결대기' },
    { value: 'final_wait', label: '종결문서대기' },
    { value: 'nego_conclude', label: '협상후종결' },
  ];  
  const progresses = {
    'notary_ready': '공증준비',
    'indict_submit': '공소장제출',
    'indict_confirm': '소송주체확인',
    'attorney_filing': '변호사입건대기',
    'pre_negotation': '소송전협상',
    'legal_wait': '법정개정대기',
    'first_wait': '1심판결대기',
    'final_wait': '종결문서대기',
    'nego_conclude': '협상후종결'
  }
  const [progress, setProgress] = useState('all');
  const handleProgress = (e) => {
    let value = e.target.value;
    setProgress(value);
    onProgressSubmit(value);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const datePickerRef = useRef(null); //달력아이콘 클릭 시 클릭해줄 위치(DatePicker)
  const handleImageClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };
  const [toDate, setToDate] = useState(null);  
  const handleToDate = (date) => {
    setToDate(date); // SelectDate 컴포넌트로부터 전달받은 selectedDate 값을 설정
    onToDateSubmit(date);
  };
  // 세부검색
  const searchOptions = [
    { value: 'all', label: '전체' },
    { value: 'seller', label: '판매상' },
    { value: 'product', label: '제품명' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="lawsuit-list">
      <div className="div-2">
        <div className="bulletin-body" id="mainbody">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">무료소송</div>
            </div>
            <p></p>
            <div className="head">
              <div className="drop-downs">
                <div className="dropdown">
                  <div className="text-wrapper-20">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  <select className="text-wrapper-20" style={{width:"100px", height:"26px", border:"none"}} id="platform" name="platform" value={platform} onChange={handlePlatform}>
                  { platformOptions.map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>
                </div>

                <div className="dropdown">
                  <div className="text-wrapper-20">진행상황</div>
                </div>

                <div className="dropdown-2">
                  <select className="text-wrapper-20" style={{width:"95px", height:"26px", border:"none"}} id="progress" name="progress" value={progress} onChange={handleProgress}>
                  { progressOptions.map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>
                </div>

                <div className="dropdown">
                  <div className="text-wrapper-20">일자</div>
                </div>

                <input className="dropdown-2" text="모바일폰" name={toDate} value={toDate} onChange={handleToDate} ref={refToDate} >
                  {/* <Input selectDate={toDate} onSelectedDateChange={handleToDate}/> */}
                </input>
                {/* <DatePickerInput className="dropdown-2"  selectDate={toDate} onSelectedDateChange={handleToDate}/> */}
              </div>

              {pageInfo ?
              <BodySearch className="body-search-instance" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-21">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">플랫폼</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-22">진행상황</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-23">제품명</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-21">판매상</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-21">공증번호</div>
                  </div>

                  <div className="body-row-cell">
                    <div className="text-wrapper-21">판매</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-21">제출일</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-21">입안일</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-21">공증일</div>
                  </div>

                  <div className="body-row-cell">
                    <div className="text-wrapper-21">종결</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame" to={`/lawsuitdetail/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-24">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-24">{platforms[prd.platform]}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-24">{progresses[prd.progress]}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{prd.product}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-25">{prd.seller_name}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-24">{prd.notarization_no}</div>
                  </div>

                  <div className="body-row-data">
                    <div className="text-wrapper-24">{prd.sold_type}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-24">{prd.submit_at ? prd.submit_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-24">{prd.entried_at ? prd.entried_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-24">{prd.notarized_at ? prd.notarized_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data">
                    <div className="text-wrapper-24">{prd.finished}</div>
                  </div>

                </Link>
                )) : <></>}

              </div>
            </div>

            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>

        {/* // 1.FooterPanel의 className을 "footer-panel-instance"로, 2.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/> 


        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <LeftMenu className="left-menu-instance" />
        <KakaoService className="design-component-instance-node-2" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <div className="search-area-2">
          <div className="input-box-wrapper">
            <div className="input-box-2">검색어를 입력해주세요</div>
          </div>

          <div className="search-2">
            <div className="text-wrapper-26"></div>
          </div>
        </div>

        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
