import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const PurchaseCard = () => {
  return (
    <div className="purchase-card">
      <div className="div-10">
        <RightMenu className="right-menu-2" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-3" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-3" />
        <NavMenu
          className="nav-menu-3"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-3" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <div className="div-11">
          <div className="heading-6">
            <div className="text-wrapper-78">주문하기</div>
          </div>
          <div className="form-2">
            <div className="div-panel">
              <div className="div-panel-body-4">
                <div className="div-item-data-2">
                  <div className="div-image-4">
                    <img className="div-img-2" alt="Div img" src="/img/div-img-1.svg" />
                  </div>
                  <div className="div-info-2">
                    <div className="heading-7">
                      <p className="text-wrapper-79">
                        브런치 블로그 상위노출, 브런치 구독, 브런치 좋아요, 브런치 조회수 활성화 마케팅
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-4">
                  <div className="div-12">
                    <div className="cell-12">
                      <div className="text-wrapper-80">기본항목</div>
                    </div>
                    <div className="cell-13">
                      <div className="text-wrapper-81">수량</div>
                    </div>
                    <div className="cell-14">
                      <div className="text-wrapper-82">가격</div>
                    </div>
                  </div>
                  <div className="div-13">
                    <div className="data-7">
                      <div className="text-wrapper-80">브런치 구독 100건</div>
                      <div className="p-pkg-summary-2">
                        <p className="text-wrapper-83">블로그에 관심있어하는 실제 사용자들로 진행합니다:)</p>
                      </div>
                    </div>
                    <div className="data-8">
                      <div className="div-box-qty-2">
                        <div className="text-wrapper-84">1</div>
                        <div className="link-21">
                          <div className="text-wrapper-85">-</div>
                        </div>
                        <div className="link-22">
                          <div className="text-wrapper-86">+</div>
                        </div>
                      </div>
                    </div>
                    <div className="data-9">
                      <div className="text-wrapper-87">40,000원</div>
                    </div>
                  </div>
                </div>
                <div className="table-5">
                  <div className="body-3">
                    <div className="div-12">
                      <div className="cell-15">
                        <div className="text-wrapper-88">블로그 페이지 URL :</div>
                      </div>
                      <div className="data-10">
                        <div className="textarea-wrapper">
                          <div className="textarea-2" />
                        </div>
                      </div>
                    </div>
                    <div className="div-12">
                      <div className="cell-16">
                        <p className="element-2">
                          일 지정 건수(일 최소 지정 건수 10)
                          <br />
                          제한없음(미지정) : 0
                        </p>
                      </div>
                      <div className="data-11">
                        <div className="textarea-wrapper">
                          <div className="textarea-3">
                            <div className="div-placeholder-2">
                              <div className="text-wrapper-89">숫자만 입력 가능합니다</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-panel-pay">
              <div className="div-panel-heading-2">
                <div className="text-wrapper-90">결제금액</div>
              </div>
              <div className="div-panel-body-5">
                <div className="div-row-2">
                  <div className="div-col-sm-2">
                    <div className="div-box-coupon-2">
                      <div className="div-14">
                        <div className="heading-8">
                          <div className="text-wrapper-91">사용가능한 쿠폰</div>
                          <div className="text-wrapper-92">2</div>
                        </div>
                      </div>
                      <div className="link-23">
                        <div className="text-wrapper-93">쿠폰선택</div>
                      </div>
                    </div>
                    <div className="div-box-point-2">
                      <div className="heading-9">
                        <div className="text-wrapper-94">캐시 사용</div>
                        <div className="small-4">(보유 캐시:0원)</div>
                      </div>
                      <div className="div-input-group-5">
                        <div className="input-6">
                          <div className="div-15">
                            <div className="text-wrapper-95">0</div>
                          </div>
                        </div>
                        <div className="span-input-group-btn-3">
                          <div className="link-24">
                            <div className="text-wrapper-93">전액사용</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-col-sm-3">
                    <div className="table-6">
                      <div className="body-3">
                        <div className="row-2">
                          <div className="cell-17">총 서비스 금액</div>
                          <div className="data-12">
                            <div className="div-wrapper-5">
                              <div className="text-wrapper-96">40,000원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row-2">
                          <div className="cell-18">ㄴ 쿠폰</div>
                          <div className="data-12">
                            <div className="div-wrapper-5">
                              <div className="text-wrapper-96">0원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row-2">
                          <div className="cell-18">ㄴ 캐시</div>
                          <div className="data-12">
                            <div className="div-wrapper-5">
                              <div className="text-wrapper-96">0원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row-2">
                          <div className="cell-19">부가세</div>
                          <div className="data-13">
                            <div className="div-wrapper-5">
                              <div className="text-wrapper-96">4,000원</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-13">
                        <div className="cell-20">
                          <div className="text-wrapper-97">총 결제금액</div>
                          <div className="small-5">
                            <div className="text-wrapper-98">(VAT 포함)</div>
                          </div>
                        </div>
                        <div className="data-14">
                          <div className="p-total-amount-2">
                            <div className="text-wrapper-99">44,000원</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-panel-pay-method-2">
              <div className="div-panel-heading-2">
                <div className="text-wrapper-100">결제방법</div>
              </div>
              <div className="div-panel-body-6">
                <div className="label-2">
                  <div className="symbol-wrapper-2">
                    <div className="symbol-10"></div>
                  </div>
                  <div className="text-wrapper-101">무통장입금 (세금계산서 미발행)</div>
                </div>
                <div className="label-2">
                  <div className="symbol-wrapper-2">
                    <div className="symbol-11"></div>
                  </div>
                  <div className="text-wrapper-101">카드결제</div>
                </div>
                <div className="label-2">
                  <div className="span-far-2">
                    <div className="symbol-10"></div>
                  </div>
                  <div className="text-wrapper-101">가상계좌 (세금계산서 발행)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="link-25">
            <div className="overlap-group-4">
              <div className="symbol-12"></div>
              <div className="text-wrapper-102"> 결제하기</div>
            </div>
          </div>
        </div>
        <div className="footer-panel-instance">
          <div className="footer-frame-3">
            <div className="flexcontainer-i-wrapper">
              <div className="flexcontainer-2">
                <p className="text-i-3">
                  <span className="text-wrapper-103">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-3">
                  <span className="text-wrapper-103">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-3">
                  <span className="text-wrapper-103">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-3">
              <div className="list-item-link-9">
                <div className="text-wrapper-104">블로그</div>
              </div>
              <div className="list-item-link-10">
                <div className="text-wrapper-104">유튜브</div>
              </div>
              <div className="list-item-link-11">
                <div className="text-wrapper-105">페이스북</div>
              </div>
              <div className="list-item-link-12">
                <div className="text-wrapper-106">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-3" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-3" />
        <JimmedProducts className="jimmed-products-3" />
        <ViewedProducts className="viewed-products-3" />
      </div>
    </div>
  );
};
