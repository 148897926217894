import * as lawsuitApi from "../api/lawsuits";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_LAWSUITLIST = "GET_LAWSUITLIST";
const GET_LAWSUITLIST_SUCCESS = "GET_LAWSUITLIST_SUCCESS";
const GET_LAWSUITLIST_ERROR = "GET_LAWSUITLIST_ERROR";

// 상품 항목 조회하기
const GET_LAWSUITDETAIL = "GET_LAWSUITDETAIL";
const GET_LAWSUITDETAIL_SUCCESS = "GET_LAWSUITDETAIL_SUCCESS";
const GET_LAWSUITDETAIL_ERROR = "GET_LAWSUITDETAIL_ERROR";

const CLEAR_LAWSUITTLIST = "CLEAR_LAWSUITTLIST";

export const getLawsuitList = (platform, progress, todate, searchType, search, pageNumber) => ({
  type: GET_LAWSUITLIST,
  payload: { platform: platform, progress: progress, todate: todate, searchType: searchType, search: search, page: pageNumber },
});

export const getLawsuitDetail = (access, id) => ({
  type: GET_LAWSUITDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearLawsuitList = () => ({ type: CLEAR_LAWSUITLIST });

const getLawsuitListSaga = createPromiseSagaList(
  GET_LAWSUITLIST,
  lawsuitApi.getLawsuitList
);

const getLawsuitDetailSaga = createPromiseSagaDetail(
  GET_LAWSUITDETAIL,
  lawsuitApi.getLawsuitDetail
);

// 사가들을 합치기
export function* lawsuitSaga() {
  yield takeEvery(GET_LAWSUITLIST, getLawsuitListSaga);
  yield takeEvery(GET_LAWSUITDETAIL, getLawsuitDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  lawsuitlist: reducerUtils.initial(),
  lawsuitdetail: reducerUtils.initial(),
};

export default function lawsuitReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LAWSUITLIST:
    case GET_LAWSUITLIST_SUCCESS:
    case GET_LAWSUITLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_LAWSUITLIST,
        "lawsuitlist",
        true
      );
      return reducerList(state, action);

    case GET_LAWSUITDETAIL:
    case GET_LAWSUITDETAIL_SUCCESS:
    case GET_LAWSUITDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_LAWSUITDETAIL,
        "lawsuitdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_LAWSUITTLIST:
      return {
        ...state,
        lawsuitlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
