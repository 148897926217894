import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReportList } from "../components/ReportList";
import { getReportList } from "../modules/reports";
import { useAuth } from "../contexts/auth/auth.context";

export const ReportListContainer = ({mallParam = '', searchTypeParam = '', searchParam = '', pageParam = 1}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const { data, loading, error} = useSelector((state) => state.reportReducer.reportlist);
  const dispatch = useDispatch();

  const onPageSubmit = (paramNumber) => {
    setPage(paramNumber);
  }

  const onSearchSubmit = (searchTypeParm, searchParm) => {
    setPage(1);
    setSearchType(searchTypeParm);
    setSearch(searchParm);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getReportList(mallParam, searchTypeParam, searchParam, pageParam));
  }, [dispatch, mallParam, searchTypeParam, searchParam, pageParam]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <ReportList 
    dataParam={data}
    currPage = {pageParam}
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}
    />
  );
}

