import React, {useState, useEffect } from "react";
import { BodyPage } from "../BodyPage";
import { BodySearch } from "../BodySearch";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LeftMenu } from "../LeftMenu";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { NavMenu } from "../NavMenu";
import { RightMenu } from "../RightMenu";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";

export const SubscribingList = ({ dataParam, currPage, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  // 세부검색
  const searchOptions = [
    { value: 'title', label: '제목' },
    { value: 'body', label: '내용' },
  ];

  // 구독 옵션  
  const subscribeOptions = { ready : "대기",	apply : "신청",	monthly : "월단위",	yearly : "년단위"}

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }
  return (
    <div className="subscribe-apply-list">
      <div className="div-2">
        <div className="bulletin-body" id="mainbody">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">구독상품현황</div>
            </div>

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-22">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-23">상품명</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-22">쇼핑몰</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-22">구독</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-22">요청자</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-22">시작일</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-22">종료일</div>
                  </div>
                </div>

                {datas ? datas.map((dtm, index, total) => (

                <div className="record-frame">
                  <div className="body-row-data">
                    <div className="text-wrapper-24">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-25">{dtm.product_name}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-24">{dtm.mall}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-26">{subscribeOptions[dtm.subscribe]}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-24">{dtm.cust_id}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-24">{dtm.started_at.substr(2,8)}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-24">{dtm.ended_at.substr(2,8)}</div>
                  </div>
                </div>

                )) : <></>}
                
              </div>
            </div>
            {pageInfo ?
              <BodyPage className="design-component-instance-node" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}
            {pageInfo ?
              <BodySearch className="design-component-instance-node" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
            :<></>}

          </div>
        </div>

        {/* // 1.css->"footer-panel-instance에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기, position/bodyBottom useState 추가,  */}
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/>

        <JimmedProducts className="design-component-instance-node-2" />
        <ViewedProducts className="design-component-instance-node-2" />
        <LeftMenu className="left-menu-instance"/>
        <KakaoService className="design-component-instance-node-3" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-3" />
        <MainLogo className="main-logo-instance" />
        <div className="search-area-2">
          <div className="input-box-wrapper">
            <div className="input-box-2">검색어를 입력해주세요</div>
          </div>

          <div className="search-2">
            <div className="text-wrapper-27"></div>
          </div>
        </div>

        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
