import * as iprApi from "../api/iprs";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_IPRLIST = "GET_IPRLIST";
const GET_IPRLIST_SUCCESS = "GET_IPRLIST_SUCCESS";
const GET_IPRLIST_ERROR = "GET_IPRLIST_ERROR";

// 상품 항목 조회하기
const GET_IPRDETAIL = "GET_IPRDETAIL";
const GET_IPRDETAIL_SUCCESS = "GET_IPRDETAIL_SUCCESS";
const GET_IPRDETAIL_ERROR = "GET_IPRDETAIL_ERROR";

const CLEAR_IPRTLIST = "CLEAR_IPRTLIST";

export const getIprList = (searchType, search, pageNumber) => ({
  type: GET_IPRLIST,
  payload: { searchType: searchType, search: search, page: pageNumber },
});

export const getIprDetail = (access, id) => ({
  type: GET_IPRDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearIprList = () => ({ type: CLEAR_IPRLIST });

const getIprListSaga = createPromiseSagaList(
  GET_IPRLIST,
  iprApi.getIprList
);

const getIprDetailSaga = createPromiseSagaDetail(
  GET_IPRDETAIL,
  iprApi.getIprDetail
);

// 사가들을 합치기
export function* iprSaga() {
  yield takeEvery(GET_IPRLIST, getIprListSaga);
  yield takeEvery(GET_IPRDETAIL, getIprDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  iprlist: reducerUtils.initial(),
  iprdetail: reducerUtils.initial(),
};

export default function iprReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IPRLIST:
    case GET_IPRLIST_SUCCESS:
    case GET_IPRLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_IPRLIST,
        "iprlist",
        true
      );
      return reducerList(state, action);

    case GET_IPRDETAIL:
    case GET_IPRDETAIL_SUCCESS:
    case GET_IPRDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_IPRDETAIL,
        "iprdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_IPRTLIST:
      return {
        ...state,
        iprlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
