import { useParams } from "react-router";
import { ContentDetailContainer } from "../../containers/ContentDetailContainer";

export const ContentDetailPage = () => {
  const { id } = useParams();  

  return (
    <ContentDetailContainer id={id} />
  )
}
