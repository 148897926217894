import axios from "./index";

export const getLawsuitList = async (params) => {
  const response = await axios.get("/lawsuits/", { params: params });
  return response.data;
};
export const getLawsuitDetail = async (formData) => {
  const response = await axios.get(`/lawsuits/${formData.id}/`);
  return response.data;
};

class LawsuitApi {
  // 판별품 데이터 생성
  static InsertLawsuit = (formData) => {
    return axios.post(`/productlawsuits/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    // return axios.post(`/productlawsuits/`, formData, {headers:{"Content-Type": "application/json; charset=utf-8"}});

  };
}
export default LawsuitApi;