import React, { useEffect } from "react";
import { useParams } from "react-router";
import { IprDetailContainer } from "../../containers/IprDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";

export const IprDetailPage = () => {
  const { id } = useParams();  

  // // 기존에 설정된 카테고리 클리어
  // useEffect(() => {
  //   if (category === 'all') {
  //     setGlobal((global) => ({
  //       ...global,
  //       menuCategoryLevel: 0,
  //       menuCategoryCode: 'all',
  //       menuCategoryName: "전체",
  //       submenuCategoryCode: '',
  //       submenuCategoryName: '',        
  //       order: 'best_desc',
  //     }));
  //   }
  // }, []);

  return (
    <IprDetailContainer id={parseInt(id)} />
  );
}
